import { configureStore } from "@reduxjs/toolkit";
import galleryReducer from "../utils/features/gallerySlice";
import paymentReducer from '../utils/features/paymentSlice';
import processingReducer from '../utils/features/processingSlice';



export default configureStore({
    reducer: {
        gallery: galleryReducer,
        payments: paymentReducer,
        processing: processingReducer
    },
});
