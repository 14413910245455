export const tobmenudata = [
    {
        id: "TOB_HN",
        name: "Home Networking >",
        desc: "Simple guides to navigate ISP installation and make the most out of in home networking.",
        url: "/homenetworking",
        photoURL: "/TOB/networking.png"
    },
    {
        id: "TOB_CAS",
        name: "Caseta >",
        desc: "Instructions and downloads for managing Caseta lighting control in your home.",
        url: "/caseta",
        photoURL: "/TOB/caseta.jpeg"
    },
    {
        id: "TOB_RNG",
        name: "Ring Doorbells >",
        desc: "Instructions and downloads for managing Ring video doorbells.",
        url: "/ringdoorbell",
        photoURL: "/TOB/ring.webp"
    }
];