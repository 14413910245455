// * DEPENDS 
import { createSlice } from "@reduxjs/toolkit";

export const paymentsSlice = createSlice({
    name: 'payable',
    initialState: {
        value: "",
        charge: "0.00",
        credit: false
    },
    reducers: {
        makePayable: (state, amount) => {
            state.value = amount
        },
        makeChargeable: (state, charge) => {
            state.charge = charge
        },
        makeCredit: (state, credit) => {
            state.credit = credit
        }
        
    }
});

export const {makePayable} = paymentsSlice.actions
export const {makeChargeable} = paymentsSlice.actions
export const {makeCredit} = paymentsSlice.actions
export default paymentsSlice.reducer