// DEPENDS 
import React, {useState, useRef} from 'react';
import { Link } from 'react-router-dom';
// data 
import {caseta} from '../../../assets/data/guides.js'

// CSS 
import "../../../CSS/TOB/Docs/Caseta.css"


const Caseta = () => {
    // used to set which step in the guide that will be shown, default to the first one on the list.
    const idx = useRef(0)
    const [guide, setGuide] = useState(caseta[idx.current]);
    const idx_capt = useRef(0)

    // for moving steps in teh workflow
    const handleGuide = () => {
            idx.current += 1
            console.log(idx)
            setGuide(caseta[idx.current])
            window.scrollTo({
                top: 120,
                behavior: "smooth"
            })
    }

    const handleBack = () => {
        if (idx.current > 0) {
            idx.current -= 1
            console.log(idx)
            setGuide(caseta[idx.current])
            window.scrollTo({
                top: 120,
                behavior: "smooth"
            })
        } else {
            window.alert("page error, please reload.")
            return null
        }
    }


    return(
        <div className='Caseta'>
            <div className='Caseta__return' id="button_wop1Home" style={{display: idx.current === 3 ? "flex" : "none"}}>
                <Link to="/TOBHomes" className='return__link'>Return to the Home Page</Link>
            </div>
            <div className='Caseta__guide' style={{display: idx.current === 3 ? "none" : "flex"}}>
                <div className='guide__viewContainer' id={`viewer_cas_${idx.current}`}>
                    
                </div>
                <div className='guide__captions'>
                    <h1 className='captions__title'>{guide.title}</h1>
                    {guide.captions.map((item) => {
                        idx_capt.current += 1
                        return(
                            <p 
                                className='captions__copy' 
                                id={`copy_cas_${idx_capt.current}`} 
                                key={`copy_cas_${idx_capt.current}`}
                            >{item}</p>
                        )
                    })}
                </div>
                <div className='guide__buttoncontainer' id='caseta_bullshit'>
                    <button className='buttonContainer__button' id='button_casetaback' onClick={handleBack} style={{opacity: idx.current > 0 ? "70%" : "0%"}}>Back</button>
                    <button className='buttonContainer__button' id='button_casetanext' onClick={handleGuide}>Next</button>
                </div>
            </div>
        </div>
    );
};

export default Caseta;