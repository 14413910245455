// depends 
import React from 'react';
import { useNavigate } from 'react-router';


//utils
import axios from 'axios';

// css 
import "../../CSS/Pages_aux/Trust.css"



const Trust = () => {
    var uin = ""
    const nav = useNavigate()

    const handleUin = (num) => {
        if (uin.length < 8) {
            uin += num
            const inj = document.getElementById("inj_trust")
            inj.innerHTML = `${uin}`
            return uin
        } else {
            window.alert("too many numbers, please retry.")
            return clearEntry()
        }

    }

    const handleUnlock = async (code) => {
        if (code !== "") {
            const btn = document.getElementById('btn_trustsubmit')
            const loader = document.getElementById('loader_trust')
            btn.style.display = 'none'
            loader.style.display = 'block'
            
            await axios.post("https://website-worker.audiocontractorsllc.workers.dev/2d1d3ae82e36374bab60b451d2ef9468816a038d2",code, {
                headers: {
                    'Content-Type': 'text/plain',
                }
            })
                .then((res) => {
                    console.log('response from the trust', res)
                    if (res.data === true) {
                        localStorage.setItem("2d1d3ae82e36374bab60", "b451d2ef9468816a038d2")
                        setTimeout(() => {
                            nav('/verify/trust/nav')
                        },[1200])
                    } else {
                        window.alert("invalid passcode")
                        btn.style.display = 'block'
                        loader.style.display = 'none'
                        clearEntry()
                    }
                })
                .catch((err) => {
                    console.log('error while getting trust verification:', err)
                    window.alert('verification failed.')
                    window.location.reload()
                })
        } else {
            window.alert('please enter a code')
        }
    }

    const clearEntry = () => {
        const inj = document.getElementById("inj_trust")
        inj.innerHTML = ``
        return uin = ""
    }

    return (
        <div className='Trust' id='Trust'>
            <div className='Trust__copyContainer'>
                <h1 className='copyContainer__title' id='tlt_trust'>Unlock</h1>
                <p className='copyContainer__caption' id='cptn1_trust'>Enter company passcode to access this page:</p>
                <p className='copyContainer__entry' id='inj_trust'></p>
            </div>
            <div className='Trust_btnContainer'>
                <button className='Trust__numberkey' id='btn_tr1' value="1" onClick={(e) => {return handleUin(e.target.value)}}>1</button>
                <button className='Trust__numberkey' id='btn_tr2' value="2" onClick={(e) => {return handleUin(e.target.value)}}>2</button>
                <button className='Trust__numberkey' id='btn_tr3' value="3" onClick={(e) => {return handleUin(e.target.value)}}>3</button>
                <button className='Trust__numberkey' id='btn_tr4' value="4" onClick={(e) => {return handleUin(e.target.value)}}>4</button>
                <button className='Trust__numberkey' id='btn_tr5' value="5" onClick={(e) => {return handleUin(e.target.value)}}>5</button>
                <button className='Trust__numberkey' id='btn_tr6' value="6" onClick={(e) => {return handleUin(e.target.value)}}>6</button>
                <button className='Trust__numberkey' id='btn_tr7' value="7" onClick={(e) => {return handleUin(e.target.value)}}>7</button>
                <button className='Trust__numberkey' id='btn_tr8' value="8" onClick={(e) => {return handleUin(e.target.value)}}>8</button>
                <button className='Trust__numberkey' id='btn_tr9' value="9" onClick={(e) => {return handleUin(e.target.value)}}>9</button>
                <button className='Trust__numberkey' id='btn_tr0' value="0" onClick={(e) => {return handleUin(e.target.value)}}>0</button>
            </div>
            <div className='Trust__ctrlContainer'>
                <button className='Trust__ctrl' id='btn_trustsubmit' onClick={() => {return handleUnlock(`${uin}`)}}>Submit</button>
                <div className='Trust__loader' id='loader_trust'></div>
                <button className='Trust__ctrl' id='btn_trustclear' onClick={() => {return clearEntry(`${uin}`)}}>Clear</button>
            </div>
        </div>
    );
};

export default Trust