// DEPENDS
import React from 'react';


// CSS 
import '../../CSS/TOB/TOBMarketing.css';

const TOBMarketing = () => {

    return(
        <div className='TOBMarketing'>
            <div className='TOBMarketing__copyContainer'>
                <h1 className='copyContainer__title' id="title_tobmarketing">Interested in more?</h1>
                <h3 className='copyContainer__subtitle' id='subtitle__tobmarketing'>Audio Contractors makes total home integrations<br/> meaningful for everyone.</h3>
                <div className='copyContainer__logoContainer'>
                    <div className="logoContainer__icon" id="mktgicon_sony"></div>                    
                    <div className="logoContainer__icon" id="mktgicon_eero"></div>
                    <div className="logoContainer__icon" id="mktgicon_sonos"></div>
                </div>
                <p className='copyContainer__copy' id="copy_tobmarketing">If audio-visual home integration is part of your dream home, drop us a line and mention Tim O'Brien Homes.</p>
            </div>
            <div className='TOBMarketing__vidContainer'>
                <iframe
                    className="vidContainer__iframe"
                    src={`https://www.youtube.com/embed/q8GV7dTVsEU`}
                    title="Brand Partner Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
        </div>
    );
};

export default TOBMarketing;


// 'contact an AC rep for more information' w/ info@AC email underneath 
// sony, sonos, eero logos (no links)
// 