// DEPENDS
import React from 'react';
import { Link } from 'react-router-dom';


// CSS 
import '../../../CSS/TOB/Docs/WifiIntro.css'

const WifiIntro = () => {
    return(
        <div className='WifiIntro'>
            <div className='WifiIntro__copyContainer'>
                <h1 className='copyContainer__title' id='title_wifiintro'>Getting Connected</h1>
                <h3 className='copyContainer__subtitle' id="subtitle_wifiintro">Modern internet connected homes begin by talking to your ISP.</h3>
                <p className='copyContainer__copy' id="copy_wifiintro">When booking the installation appointment with your ISP, you should ask: <br/><br/><span id="quote_wifiintro">"Will you be supplying me with a modem/router ('combo') or separate modem and router?"</span><div className='copyContainer__copy' id="mview_wifiintro"><p id="mview_wifiintrocopy">"Will you be supplying me with a modem/router ('combo') or separate modem and router?"</p></div><br/><br/>The answer to this question will determine the instructions to follow:</p>
            </div>
            <div className="WifiIntro__controlContainer">
                <Link to='/TOBHomes/homenetworking/option1' className='controlContainer__option' id="control_option1">
                    <div className='option__imgContainer' id="img_option1"></div>
                    <div className='option__copyContainer'>
                        <h1 className='copyContainer__title' id='title_option1'>Option 1</h1>
                        <h3 className='copyContainer__subtitle' id="subtitle_option1">Modem & Router</h3>
                        <p className='copyContainer__copy' id="copy_option1">This ISP package inlvolves installation of separate Router and Modem units.</p>
                    </div>
                </Link>
                <Link to="/TOBHomes/homenetworking/option2" className='controlContainer__option' id="control_option2">
                    <div className='option__imgContainer' id="img_option2"></div>
                    <div className='option__copyContainer'>
                        <h1 className='copyContainer__title' id='title_option2'>Option 2</h1>
                        <h3 className='copyContainer__subtitle' id="subtitle_option2">Modem/Router "Combo"</h3>
                        <p className='copyContainer__copy' id="copy_option2">This ISP package includes a combined Modem/Router in a signle unit.</p>
                    </div>
                </Link>
            </div>
            <Link to="/TOBHomes" className='WifiIntro__back'>
                <h1 className='back__copy'>⇠ Go Back</h1>
            </Link>
        </div>
    );
};

export default WifiIntro;
