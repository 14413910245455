// depends 
import React, { useRef, useEffect, useState } from 'react';
import {useForm} from 'react-hook-form';
import { useNavigate } from 'react-router';
import emailjs from '@emailjs/browser';


// utils 
import caseid from '../../utils/handlers/caseid';
import sendReceipt from '../../utils/fetchers/sendReceipt';
import { fetchEJSkey, fetchESJserviceID } from '../../utils/fetchers/fetchEJSdata';

// css 
import '../../CSS/Pages_customerSupport/NewTicket.css';

const NewTicket = () => {
    const nav = useNavigate();
    const {register, handleSubmit, setValue, formState: {errors}} = useForm({
        defaultValues: {
            name: '',
            businessName: '',
            email: '',
            phone: '',
            address: '',
            category: '',
            date: '',
            purchaseDomain: '',
            commercialCustomer: false,
            description: '',
            UUID: '',
        }
    })

    const [submitting, setSubmitting] = useState();
    var EJS = useRef({
        service: "",
        template: "template_tib9vn3",
        key: "",
        CStemplate: "template_71knjd4"
    })

    // ? used to track if this is a commercial customer reaching out through the website outside of the context of the data object since we are using it for some 
    // ? form control
    const [com, setCom] = useState(false);
    const updateComCustRef = () => {
        if(com === false) {
            setCom(true)
        } else {
            setCom(false)
        }
    }

    // used the check if the errors object is empty in order to show or hide the container at the bottom of the screen. 
    const isEmpty = (obj) => {
        if (Object.keys(obj).length === 0) {
            return false
        } else {
            return true
        }
    };

    // used to check the existence of a specified error, to apply element.style properties to inputs.
    const hasError = (obj, target) => {
        if (Object.keys(obj).includes(target)) {
            return true
        } else {
            return false
        }
    };

    const formSubmit = (data) => {
        setSubmitting(true)
        emailjs.send(EJS.current.service, EJS.current.template, data, EJS.current.key)
            .then((res) => {
                // console.log(res.status)
                sendReceipt(EJS.current.service, data, EJS.current.key) 
                    setTimeout(() => {
                        nav('/confirmation')
                    },[3000])
            
            })
            .catch((err) => {
                window.alert("we are encountering issues. please try again.")
                console.log('there was an error on the emailing:', err)
            })
    }

    // get EJS info 
    useEffect(() => {
        if (EJS.current.service === "" && EJS.current.service === '') {

            async function getEJS() {
                await fetchESJserviceID()
                    .then((res) => {
                        EJS.current.service = res
                    })
                    .catch((err) => {
                        window.alert("there was an error loading this page")
                        console.log("err getting service", err)
                        setTimeout(() => {
                            window.location.reload()
                        },[5000])
                    })
                
                await fetchEJSkey()
                    .then((res) => {
                        EJS.current.key = res
                    })
                    .catch((err) => {
                        window.alert("there was an error loading this page")
                        console.log("err getting key", err)
                        setTimeout(() => {
                            window.location.reload()
                        },[5000])
                    })
            }
            getEJS()
        }

        // create support case ID, assign it to this render of the form in the background 
        setValue('UUID', caseid())
    },[])

    return(
        <div className='NewTicket'>
            <div className='newTicket__loadingContainer' style={{display: submitting ? 'flex' : 'none'}}>
                <div class="three-quarter-spinner"></div>
            </div>
            <form id='form_newTicket' className='newTicket__form' onSubmit={handleSubmit(formSubmit)} style={{display: submitting ? 'none' : 'flex'}}>
                <h2 className='form__sectionTitle'>Contact Information</h2>
                <div className='comCust_container'>
                    <label id='label_comCust' className='newTicket__label'>If you are reaching out on behalf of a commercial business, please check the box </label>
                    <input id="check_commercialCustomer" className='newTicket__box' type="checkbox" onClick={() => {updateComCustRef()}} {...register("commercialCustomer")} />
                </div>
                <div className='form__fieldCont'>
                    <div className='fieldCont__field'>
                        <label id='label_name' className='newTicket__label'>Name* {com === true ? "(point of contact name)":""}</label>
                        <input
                            id='name'
                            name='name'
                            className='newTicket__input'
                            type='text'
                            style={{border: hasError(errors,'name') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("name", { required: true, maxLength: 50 })} 
                        />
                    </div>
                    <div className='fieldCont__field' style={{display: com === true ? 'flex' : 'none'}}>
                        <label id='label_businessName' className='newTicket__label'>Name of Business*</label>
                        <input
                            id='businessName'
                            name='businessName'
                            className='newTicket__input'
                            type='text'
                            style={{border: hasError(errors,'businessName') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("businessName", { required: false, maxLength: 50 })} 
                        />
                    </div>
                    <div className='fieldCont__field'>
                        <label id='label_email' className='newTicket__label'>Email*</label>
                        <input
                            id='email'
                            name='email'
                            className='newTicket__input'
                            type='email'
                            style={{border: hasError(errors,'email') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("email", { required: true, maxLength: 50 })} 
                        />
                    </div>
                    <div className='fieldCont__field'>
                        <label id='label_phone' className='newTicket__label'>Phone*</label>
                        <input
                            id='phone'
                            name='phone'
                            className='newTicket__input'
                            type='tel'
                            style={{border: hasError(errors,'phone') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("phone", { required: true, maxLength: 50 })} 
                        />
                    </div>
                    <div className='fieldCont__field'>
                        <label id='label_address' className='newTicket__label'>Address (street and city only)</label>
                        <input
                            id='address'
                            name='address'
                            className='newTicket__input'
                            type='text'
                            style={{border: hasError(errors,'address') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("address", { required: true, maxLength: 50 })} 
                        />
                    </div>
                </div>

                <h2 className='form__sectionTitle'>Support Information</h2>
                <div className='form__fieldCont'>
                    <div className='fieldCont__field'>
                        <label id='label_category' className='newTicket__label'>Please select a type of issue*</label>
                        <select 
                            id='select_category' 
                            name='category'
                            className='newTicket__select'
                            style={{border: hasError(errors,'category') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("category", { required: true })}>
                                <option></option>
                                <option>Audio Not Working</option>
                                <option>Video Not Working</option>
                                <option>Internet Connection Not Working / Poor Performance</option>
                                <option>Device Failure / Not Powering On</option>
                                <option>Home Automation Not Working</option>
                                <option>Purchased New Device, Need Integration Help</option>
                                <option>Other, please describe issue below</option>
                                {com === true ? 
                                    <option> Commercial Control System (Building/Room Control) Not Working </option>
                                    : null
                                }
                                {com === true ? 
                                    <option> Video Conferencing System </option>
                                    : null
                                }
                        </select>
                    </div>
                    <div className='fieldCont__field'>
                    <label id='label_purchaseDomain' className='newTicket__label'>Did you purchase the effected equipment from Audio Contractors?*</label>
                        <select 
                            id='select_purchaseDomain' 
                            name='purchaseDomain'
                            className='newTicket__select'
                            style={{border: hasError(errors,'purchaseDomain') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("purchaseDomain", { required: false, maxLength: 50 })}>
                                <option></option>
                                <option>Yes, I Purchased this from Audio Contractors</option>
                                <option>No, I Did Not Purchase this from Audio Contractors</option>
                        </select>
                    </div>
                    <div className='fieldCont__field'>
                        <label id='label_date' className='newTicket__label'>Date of Purchase (or Install)</label>
                        <input
                            id='date'
                            name='date'
                            className='newTicket__input'
                            type='month'
                            style={{border: hasError(errors,'date') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("date", { required: false })} 
                        />
                    </div>
                    <div className='fieldCont__field' id='description'>
                        <textarea 
                            id="newTicket_description" 
                            className='newTicket__textarea' 
                            placeholder="Describe your issue here .."
                            style={{border: hasError(errors,'date') ? "2px solid #F20000" : "2px solid #1E132A"}}
                            {...register("description", {required: true})} 
                        />
                    </div>                
                </div>

                <div className='newTicket__errors' style={{display: isEmpty(errors) ? "block" : "none"}}>
                    <p className='errors__dialog'>Please resolve the following errors before submitting</p>
                    <ul className='errors__list'>
                        {errors.name && <li className='newTicket__error'>&#8226; Please enter your name</li>}
                        {errors.businessName && <li className='newTicket__error'>&#8226; Please enter the name of your organization</li>}
                        {errors.email && <li className='newTicket__error'>&#8226; Please enter your email</li>}
                        {errors.phone && <li className='newTicket__error'>&#8226; Please enter your phone number</li>}
                        {errors.address && <li className='newTicket__error'>&#8226; Please enter your address (street and city only)</li>}
                        {errors.category && <li className='newTicket__error'>&#8226; Please select a type of issue, select 'other' if not listed</li>}
                        {errors.date && <li className='newTicket__error'>&#8226; Please select a month and year of purchase from Audio Contractors</li>}
                        {errors.description && <li className='newTicket__error'>&#8226; Please describe your issue before submitting</li>} 
                    </ul>
                </div>

                <div className='UUID_cont' style={{display: "none"}}>
                    <input
                        id='UUID'
                        name='UUID'
                        className='newTicket__input'
                        type='text'
                        readOnly
                        {...register("UUID")} 
                    />
                </div>

                <input type="submit" className='newTicket__submit' id='btn_newTicketSubmit' lable="submit" value="Submit" style={{margin: "2% 0 2% 1%"}}/>
            </form>
        </div>
    )
}

export default NewTicket;