// * DEPENDS
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// * CSS
import "../../CSS/Pages_payments/Processing.css"

// * COMPS
import Active from './ProcessingStates/Active';
import Success from './ProcessingStates/Success';
import Error from './ProcessingStates/Error';

const Processing = () => {
    // state controlled display of sys. state
    const [current, setCurrent] = useState({
        active: true,
        success: false,
        error: false
    })

    // reading the purchase information and verifying with server
    const purchase = useSelector(state => { return state.processing.value.payload })
    useEffect(() => {
        //for testing purposes
        //axios.post('http://127.0.0.1:8787/purchase ', purchase)

        axios.post('https://website-worker.audiocontractorsllc.workers.dev/purchase ', purchase)
        .then((response) => {
                if (response.data.result === true) {
                    setTimeout(() => {
                        setCurrent({
                            active: false,
                            success: true,
                            error: false
                        })
                    }, 3000)
                } else if (response.data.result === false && response.data.details === 'transaction declined') {
                    setTimeout(() => {
                        setCurrent({
                            active: false,
                            success: false,
                            error: true,
                            status: "unauth"
                        })
                    }, 3000)
                } else if (response.data.result === false && response.data.details === 'server error') {
                    setTimeout(() => {
                        setCurrent({
                            active: false,
                            success: false,
                            error: true,
                            status: "fraud"
                        })
                    }, 3000)
                }
            })
        .catch((error) => {
            setCurrent({
                active: false,
                success: false,
                error: true,
                status: error
            })
        })

    }, [purchase])
    


    if (current.active) {
        return(
            <div className='Processing'>
                <Active />
            </div>
        )
    } 
    
    if (current.success) {
        return (
            <div className='Processing'>
                <Success />
            </div>  
        )
    } 
    
    if (current.error) {
        return (
            <div className='Processing'>
                <Error />
            </div>
        )
    }
};

export default Processing;


