// * DEPENDS.
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// * COMPS
import VerifyForm from './VerifyForm';
import Help from './Help';
import Checkout from './Checkout.js';
import Processing from './Processing';


// * CSS 
import '../../CSS/Pages_payments/WindowFrame.css'

const WindowFrame = () => {

    return(
        <div id="WindowFrame" className='WindowFrame'>
            <Routes> 
                <Route exact path="/" element={<VerifyForm />}/>
                <Route exact path="/help" element={<Help/>} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/checkout/processing" element={<Processing />} />
            </Routes>
        </div>
    )
};

export default WindowFrame;