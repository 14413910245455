// depends 
import React from 'react';
import { Link } from 'react-router-dom';


// css 
import '../../CSS/Pages_aux/TrustNav.css'


const TrustNav = () => {

    return(
        <div className='TrustNav'>
        <Link to='/kh' className='TrustNav__link'>Knowledge Base</Link>
        <Link to='/gettingstarted' className='TrustNav__link'>Getting Started Forms</Link>
        {/* <Link to='/kh' className='TrustNav__link'>Knowledge Base</Link> */}
        </div>
    );
};

export default TrustNav;