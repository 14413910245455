// depends 
import React from "react";
import { Link } from "react-router-dom";

// css 
import "../../CSS/Pages_customerSupport/CustomerSupportMenu.css"

const SupportMenu = () => {
    return(
        <div className="SupportMenu">
            <div className="SupportMenu__container" id="cntnr_sm1">
                <h1 className="SMcontainer__title">Submit a Ticket</h1>
                <p className="SMcontainer__copy">Our customer support team is here to assist you!</p> 
                <p className="SMcontainer__copy">If you are experiencing issues with your home's installation and would like to speak with a support agent over email, or schedule an in-person visit with a technician please click the button below to fill out a brief form telling us about your issue.</p>
                <p className="SMcontainer__copy">We review every ticket and will respond within typical business hours, exl. holidays and weekends.</p>
                <Link to="/support/newTicket" className="SMcontainer__link">New Ticket</Link>   
            </div>
            {/* <div className="SupportMenu__container" id="cntnr_sm2">
                <p className="SMcontainer__subcopy">Our typical office hours are 8:00a-4:00p, Monday through Friday with weekends available by appointment.</p>
                <a href="tel:6088350556">
                    <p className="SMcontainer__information" id="info_phone"><span id="icon_phone"></span>608 - 835 - 0556</p>
                </a>
                <a href="mailto:info@audiocontractorsllc.com">
                    <p className="SMcontainer__information" id="info_email"><span id="icon_email"></span>info@audiocontractorsllc.com</p>
                </a>
                <a href="https://goo.gl/maps/9BRRkMkA3fwEeDy37" target="_blank" rel="noopener noreferrer">
                    <p className="SMcontainer__information" id="info_address"><span id="icon_map"></span>1015 North Main Street Suite F Oregon, WI 53575</p>
                </a>
            </div> */}
        </div>
    )
};

export default SupportMenu;
