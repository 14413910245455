export const guides_1 = [
    {
        id: 0,
        title: "Show this guide to your technician",
        captions: [
            "When your ISP technician arrives for your installation, you can show them this guide.", 
            "They will likely already know what process to follow but this material is intended to be supplimental to ISP practices and ensure that your connection is strong and suitable for your home.",
            "Alternatively, if you chose self-installation from your ISP, you can use this guide to quickly install your modem and router units."
        ]
    },
    {
        id: 1,
        title: "Modem Installation",
        captions: [
            "The modem facilitates connection between the home and 'outside world'.",
            "The 'outside world' is known as WAN (wide-area network), so we will connect the WAN port to the existing CAT6 wire labeled WAN.",
            "If you selected self-installation from your ISP the modem has likely already been provisioned for you. If the unit does not obtain a connection after 20 minutes, contact your ISP as further steps might be required.",
            "This wire is ran to a port location in the Pantry or Hall Closet. The next steps will require being at this port location."
        ]
    },
    {
        id: 2,
        title: "Router Installation",
        captions: [
            "The WAN wire from the basement will correspond to the labled port on the wall. Use a CAT6 jumper to connect the WAN port to the WAN input on the Router.",
            "Then, connect another CAT6 jumper from one of the LAN ports on the router into the LAN port on the wall.",
            "This LAN connection will feed LAN connection to the switch, thus enabling all port locations in the home.",
            "This is covered more in the next step."
        ]
    },
    {
        id: 3,
        title: "Return to the Head End (Basement)",
        captions: [
            "Now that the LAN connection has been made from the router, ensure that the CAT6 wire labeled 'LAN' is connection to any port on the switch.",
            "As mentioned in the previous step, this switch allows for all data port locations in the home to have a stable, hardwired connection to the internet (without need for wifi connection)",
            "This is excellent for IoT devices such as Alexa's, Smart Speakers, Smart TV's and more.",
            "Additionally, with the home's router now located on the main floor, wifi connections more likely to be fast and reliable."
        ]
    },
    {
        id: 4,
        title: "Congrats!",
        captions: [
            "That's it! Double check that all devices indicate connection (generally a light on the front or side of device) one more time.",
            "Follow any additional set up and first time connection instructions provided from your ISP.",
            "Then enjoy the perks of modern home networking!"
        ]
    },
    {
        // "null congrats" is being used to satisfy the controller so that it doesn't break when using JS CSS to hide/show content for workflow. 
        // ignore, but always include at the end of a guide!
        id: 5,
        title: "Null Congrats",
        captions: [
            "Im being used to keep the code from breaking",
            "what an exciting job that i have!",
        ]
    }

]

export const guides_2 = [
    {
        id: 0,
        title: "Show this guide to your technician",
        captions: [
            "When your ISP technician arrives for your installation, you can show them this guide.", 
            "They will likely already know what process to follow but this material is intended to be supplimental to ISP practices and ensure that your connection is strong and suitable for your home.",
            "Alternatively, if you chose self-installation from your ISP, you can use this guide to quickly install your modem and router units."
        ]
    },
    {
        id: 1,
        title: "Modem/Router 'Combo' installation",
        captions: [
            "After ISP connects outside line, LAN connection from Modem/Router 'combo' will need to be made to any port on the Network Switch that has been installed.",
            "This connection is vital, as it will enable the data port locations in the home to achieve outside connection through the Modem/Router 'Combo' device."
        ]
    },
    {
        id: 2,
        title: "Congrats!",
        captions: [
            "That's it! Double check that all devices indicate connection (generally a light on the front or side of device) one more time.",
            "Follow any additional set up and first time connection instructions provided from your ISP.",
            "Then enjoy the perks of modern home networking!"
        ]
    },
    {
        // "null congrats" is being used to satisfy the controller so that it doesn't break when using JS CSS to hide/show content for workflow. 
        // ignore, but always include at the end of a guide!
        id: 3,
        title: "Null Congrats!",
        captions: [
            "Im being used to keep the code from breaking",
            "what an exciting job that i have!",
        ]
    }
]

export const caseta = [
    {
        id: 0,
        title: "We've Done The Hard Part",
        captions: [
            "Prior to your move-in, our teams integrated the Pico lighting remotes that your New Home Sales Professional should have introduced you to.", 
            "Additionally, you have been provided a Lutron Caseta Smart Bridge (model: L-BDG2-WH) that can be used to expand and personalize your home's automated lighting control.",
            "Once the Lutron Caseta Bridge has been set up, you will be able to access your home's lighting through the mobile app."
        ]
    },
    {
        id: 1,
        title: "Self Installation",
        captions: [
            "After your ISP installation has been completed, connect the Bridge to any open port on the Network Switch.",
            "Alternatively, you can connect the Bridge to any data port location in the home.",
            "Lastly, connect the provided power supply for the Caseta Smart Bridge. (Do not connect power before connecting network)"
        ]
    },
    {
        id: 2,
        title: "Download the Caseta Mobile App",
        captions: [
            "You will need to download the iOS or Android mobile applications to interact with the smart bridge.",
            "For convenience, links are provided below or the QR code above can be scanned.",
            "Question about set up? Reach out to Lutron tech support: 1 (844) 588-7661",
            "These applications will walk you through set up and personalization of your Lutron Caaseta smart lighting system.",
            "Learn how you can integrate the Caseta system with voice command services like Siri, Alexa, and Google Voice."
        ]
    },
    {
        // "null congrats" is being used to satisfy the controller so that it doesn't break when using JS CSS to hide/show content for workflow. 
        // ignore, but always include at the end of a guide!
        id: 3,
        title: "Null Congrats!",
        captions: [
            "Im being used to keep the code from breaking",
            "what an exciting job that i have!",
        ]
    }
]

export const ring = [
    {
        id: 0,
        title: "We've Done The Hard Part",
        captions: [
            "Prior to your move-in, our teams installed the Ring Doorbell and Chime sound module in your home.", 
            "Questions for set up? Call Ring tech support: 800-656-1918",
            "The model that was installed in your home contains modern features like motion-sensor video recording and remote live camera viewing.",
        ]
    },
    {
        id: 1,
        title: "Accessing Modern Features",
        captions: [
            "The features mentioned in the previous step are accessed through the Ring Doorbell mobile app.",
            "After signing in or creating a new account, select 'Set Up A Device' and provide location as well as a name for the device.",
            "The application will walk you through physical set up steps. Ensure that the doorbell is able to obtain a connection through Wi-Fi."
        ]
    },
    {
        id: 2,
        title: "Congrats!",
        captions: [
            "After completing setup with the Ring mobile application, you can reach out to Audio Contractors if you are insterested in further integrating Ring Video Surveillance into Smart Home services you are bringing to the new home.",
            "We hope you enjoy these perks of modern home surveillance with the Ring Video Doorbell.",
        ]
    },
    {
        // "null congrats" is being used to satisfy the controller so that it doesn't break when using JS CSS to hide/show content for workflow. 
        // ignore, but always include at the end of a guide!
        id: 3,
        title: "Null Congrats!",
        captions: [
            "Im being used to keep the code from breaking",
            "what an exciting job that i have!",
        ]
    }
]


export const knowledgehome = [
    {name: "Universal Remote Control", url: "urcremotes"},
    {name: "Sonos", url: "sonos"},
    {name: "Control4", url: "control4"},
    {name: "QSYS", url: "qsys"},
    {name: "Shop Policies", url: "shop"},
]


export const URCdirectory = [
    {
        key: "URC000",
        title: "0. CEC Control",
        content: [
            {
                type: "bullets",
                content: [
                    "Before starting, it is key to ensure that CEC (consumer electronics control) is turned off on all devices passing or recieving HDMI in the system. These settings are found in the settings menu, and are often known by different names. Below are a few quick references for most models: "
                ]
            },
            {
                type: "links",
                content: [
                    {name: "Bravia Sync", url: "https://www.sony.co.uk/electronics/support/articles/00126149", key: "URC000_L1"},
                    {name: "SimpLink", url: "https://www.lg.com/uk/microsites/tv/simplink-function", key: "URC000_L2"},
                    {name: "ANYNET", url: "https://support.atlona.com/hc/en-us/articles/115005254647-KB01135-Enabling-CEC-Anynet-on-Samsung-TV-s-", key: "URC000_L3"}
                ]
            }
        ]
    },
    {
        key: "URC001",
        title: "1. Configure Home",
        content: [
            {
                type: "callout",
                content: "Before beginning programming, take note of which device each IR output is reaching to. The outputs are numbered on the base station. Consider adding lables to the IR cables for better clarity."
            },
            {
                type: "bullets",
                content: [
                    "Select “Configure Home” find and add the Remote model and/or the base station that you are going to program.  Add them to the project by dragging or clicking the arrow keys in the dialog box.",
                    "If there are multiple systems in the house, you can create different projects for each, OR you may sort the projects in a single file by adding Rooms and placing remotes and base stations into them accordingly."
                ]
            },
            {
                type: "image",
                content: "45c33c74-b12e-49c3-faf3-f7632c521800"
            }
        ]
    },
    {
        key: "URC002",
        title: "2. Page Layout",
        content: [
            {
                type: "callout",
                content: [
                    "Before starting programming the UI of the remote, it is key to ask and understand what the customer’s primary media sources will be. This will; drive the placement of each device on the main screens."
                ]
            },
            {
                type: "image",
                content: "43e4bc81-f5f2-42c0-9943-f1e949eca700"
            },
            {
                type: "bullets",
                content: [
                    "Select “Create & Name Devices” and add primary video sources from most used video on bottom right, to the top on the Main page 1. You pick a “category” in its drop down menu on an MX-790 for the icon you are looking for. Any audio sources should be placed on the left, starting at the bottom.  See example below.  If it will look better, we should hide the text of the device by right clicking on the button on the main page and selecting “Hidden Text”.",
                    "Put key devices that client does not need to see or use on Main page 2 (unless requested by client). These devices include: AV Recievers, Soundbars, AV Switchers/Matrix, and TV's if the customer is using and external streaming device."
                ]
            },
            {
                type: "image",
                content: "cf45e80c-7263-45b6-1314-11adfaa8b600"
            },
        ]
    },
    {
        key: "URC003",
        title: "3. IR Codesets",
        content: [
            {
                type: "bullets",
                content: [
                    "Select “Database”, then repeat the following steps for each device in the system:",
                    "select the device from the dropdown menu.",
                    "“IR Data From” >  Brand > Model # for what you are trying to program. Once selected, select “Load Codeset” on the right hand side of the dialog."
                ]
            },
            {
                type: "image",
                content: "46a473fa-1c71-4561-ffef-a12f24dfd900"
            },
            {
                type: "bullets",
                content: [
                    "If you are unsure of a codeset you are able to test the codes using the Test button while the remote is connected to your Laptop via USB cable and the remote is pointed at the device you are testing.",
                    "When possible, use codes that give discrete power commands and input selection if possible. Devices that almost always have descrete power functionality are TVs, AVRs, Projectors, DirecTV/Dish Network, and Sony Blurays.",
                    "There are programming workarounds for some devices that don’t have discrete commands. In a pinch you can create a macro out of a “Play” command for On, and “Play > 5 sec delay > Power” for off.",
                    "Test codes to make sure discrete power commands work. Power on and Power off commands may be on the hard power buttons or soft buttons on another page in the device. If you cannot find the exact model, try another model and test."
                ]
            }
        ]
    },
    {
        key: "URC004",
        title: "4. Editing Labels",
        content: [
            {
                type: "bullets",
                content: [
                    "Select “Edit Buttons”, then move commonly used buttons, and buttons used to program macros, to page 1 and 2 of the selected source. Move all other buttons to remaining pages and select the “hide” option on these pages.",
                    "If you require a button that is not available in the codeset, or wish to copy a physical button into the device menu, you can add a new name to an empty space on this screen. Naming the button now is crucial, we will program its functionality later on.",
                    "Check out the links below for example button layouts for each device."
                ]
            },
            {
                type: "links",
                content: [
                    {name: "Roku", url: "https://drive.google.com/file/d/1Jz7CSCNx-8ECFTaWpdZQZb7OzX29bMk8/view?usp=share_link", key: "URC004_L1"},
                    {name: "AVR", url: "https://drive.google.com/file/d/13eSmFEJeyiXB1T6C4CgTsBKaGQhzflo-/view?usp=share_link", key: "URC004_L2"},
                    {name: "BlueRay", url: "https://drive.google.com/file/d/13eSmFEJeyiXB1T6C4CgTsBKaGQhzflo-/view?usp=share_link", key: "URC004_L3"},
                    {name: "TV Streaming Apps", url: "https://drive.google.com/file/d/1FdGs7fcaIVl0xaBiTiGLo-ocXAAKEqbR/view?usp=share_link", key: "URC004_L4"},
                    {name: "TV Settings", url: "https://drive.google.com/file/d/1762HfUP9R5ymSrRqs8RO7JykC70-vVUq/view?usp=share_link", key: "URC004_L5"},
                    {name: "Zone 2", url: "https://drive.google.com/file/d/1B9DxuYT0dlNi3Rv8G3iQsKQoRKxogKCz/view?usp=share_link", key: "URC004_L6"},
                    {name: "Spectrum Cable Box", url: "https://drive.google.com/file/d/1o49BbkG5vZDJEGMr85-MdXuNZH9Lef3J/view?usp=share_link", key: "URC004_L7"},
                    {name: "TV with Streaming Device", url: "https://drive.google.com/file/d/1FdGs7fcaIVl0xaBiTiGLo-ocXAAKEqbR/view?usp=share_link", key: "URC004_L8"}
                ]
            },
            {
                type: "subsection",
                title: "Power Commands",
                content: [
                    "Type in discrete power on and off commands on the second page as shown above. If the device is a cable box, or device with a power toggle, add/move the Power toggle button to page 1.",
                    "On the device you are editing, copy and paste the power toggle or the descrete power button IR code(s) from the hard power button(s) Macro Window, to the Power button(s) you created. Or drag the command(s) over using “IR Database Navigator” from the “tools” menu bar."
                ]
            },
            {
                type: "subsection",
                title: "Zone 2 Considerations",
                content: [
                    "When using Zone 2 or a “Whole House Audio” stereo receiver, put any source select commands onto page 1, and the discrete power commands onto page 2.",
                    "If there is a source like a CD player that requires additional control, then add that source to the Main page 2. ",
                    "Later when macro programming, when they select the CD Player source from page 1 of House Audio, have it jump to the CD Player page.",
                ],
                image: "25e68ade-3ff7-4a69-91dc-7d825579c500"
            }
        ]
    },
    {
        key: "URC005",
        title: "5. Recording Macros",
        content: [
            {
                type: "bullets",
                content: [
                    "Select a button on the remote model on-screen and view the Macros Recording window that appears in the bottom right corner of the screen (default). You will notice that there are macro-ed IR commands listed under most buttons, these can be re-ordered and removed.",
                    "Press red record button to start recording new commands to the button macro. Press record button again to stop recording.",
                    "You can add Delays and Jumps with the additional buttons along the top of this panel. These will be appended to the command list, you will need to re-order them where desired, with discrete codes create a macro on each device button from the Main Page(s)."
                ]
            },
            {
                type: "subsection",
                title: "Cablebox Example",
                content: [
                    "Cable Box Example Macro: Jump to please wait screen, select AV receiver input CBL/SAT, delay, TV select HDMI input, delay, STOP command for any disc-based source or streaming source, Jump to “Cable page 1”. Include a Power On or Play command in the macro for disc-based devices like Blu-Ray players. A Play command will turn on most DVD/Blu-ray players if a discrete On command can not be found.",
                    ],
                image: "d17af020-4311-4413-19df-cf066eceb300"
            },
            {
                type: "subsection",
                title: "Power Off Macro",
                content: [
                    "Create the Main Page Red hard button “System Off” power macro to set the source of the room to what we want it to come on to the next time the system is turned on. This will be the same macro steps as the source selection button followed by the discrete off commands for the devices. Finish the macro by jumping to Main Page 1.",
                    "Example: Jump to Please Wait page, Streaming device STOP, delay, TV to HDMI 1, delay, Receiver to the CBL/SAT input, delay, TV power Off, delay, Blu-ray power off, delay, Receiver power off, Jump to “Main page 1”."
                    ],
                image: "e39bdb33-e05d-4732-4261-3958c95c5400"
            },
            {
                type: "subsection",
                title: "Power On Macro",
                content: [
                    "Create the Main Page Green hard button “System On” power macro that turns on the key components of the entire system. Exclude disc-based devices (Blu-Ray) from this macro.  End the macro by selecting the source page jump for the source we set the system to in our off macro (usually a Cable, Sat., or streaming source).  If you have a source on the main page that doesn’t require any control from the remote(SONOS), remove the “jump” in the macro window. This will allow the system to run the macro and stay on the main page while still switching properly and allowing control of the volume we will punch through in the next step.",
                    ],
                image: "34c9535c-9b7e-4d21-2358-c04414e16000"
            },
            {
                type: "subsection",
                title: "Streaming Device Macro",
                content: [
                    "Use the example macro below to create a flexible order of commands for successfully accessing an external streaming device.",
                    ],
                image: "9d6c9315-8abd-48fd-7e9a-260a96693500"
            },

        ]
    },
    {
        key: "URC006",
        title: "6. Punch Throughs",
        content: [
            {
                type: "bullets",
                content: [
                    "Select “Punch Through”",
                    "Punch the “Main”, “Power On/Off” to all source pages.",
                    "Punch the AV Receivers “Volume, Mute” to all sources including main page. If there is not a Receiver controlling the volume, then use the TV volume."
                ]
            },
            {
                type: "image",
                content: "e4398c51-ccd7-48ef-6104-6ee8f6c10500"
            }
        ]
    },
    {
        key: "URC007",
        title: "7. RF Control",
        content: [
            {
                type: "bullets",
                content: [
                    "Select “RF Control”",
                    "You should be using RF only if you have a base station (not IR/RF) with emitters. Select the appropriate IR output port for each device.",
                ]
            },
            {
                type: "image",
                content: "f1aa07ea-d60b-4553-ae1e-117c89574000"
            }
        ]
    },
    {
        key: "URC008",
        title: "8. Testing + Final Editing",
        content: [
            {
                type: "bullets",
                content: [
                    "Hide all pages of a source that clients do not need access to unless requested.",
                    "Hide Main 2 Page 1 by right clicking and selecting “Is Hidden”",
                    "Verify all Macros end with the desired page jump if the first thing is a “Please Wait” jump. If not the remote will lock onto the please wait screen until you pull the batteries."
                ]
            },
            {
                type: "bullets",
                content: [
                    "Test all command keys.",
                    "Test all Macros and watch for proper page jumps.",
                    "Test volume on all source devices.",
                    "Test for RF interference. Navigate around the cable box guide or Apple TV menu. If the Signal light on the RF base is staying lit momentarily after you’ve pressed a button on the remote, then there is some interference, and the base should be adjusted. You can also observe this by watching the blink of the emitters. It will match what the signal light on the base is doing."
                ]
            },
        ]
    },
    {
        key: "URC009",
        title: "9. Orientation",
        content: [
            {
                type: "bullets",
                content: [
                    "Introducing the customer to the system for the first time can be intimidating, ease into the explanation by giving concrete use cases of each button and command on the remote. Provide additional details about what is happening internally. Some talking points to consider:",
                    "Are they using TV applications for streaming? or a Roku / Apple TV? Explain the difference, and the relationships of the devices.",
                    "Are they using whole home audio? explain the purpose and advantage of zone 2.",
                    "Do they have multiple source? introduce the AVR as “a device that switches between sources”, detail how is delivers audio to the room.",
                    "Will they switch between music and video streaming? walk them through each workflow from power on to power off."
                ]
            }
        ]
    },
    {
        key: "URC010",
        title: "10. Eric's Tips + Tricks",
        content: [
            {
                type: "bullets",
                content: [
                    "You can enlarge the text on buttons if the owner can not see well. Do this by …",
                    "When uploading a remote to change programming, first create a new, “empty” blank, remote from the file menu. This will ensure that the RF base code does not change.",
                    "If learning codes from URC remote into Apple TV you can choose a bluray player database code. Apple TV does not care which codes you learn for control so this works well.",
                    "Use “Pages” from the “View” menu to drag a device from Main Page 1 to Main Page 2 etc. This will keep macros and other programming in place vs deleting and re-adding the device to the other page.",
                    "Test codes and/or macros with remote connected to laptop to save time.",
                    "Copy and paste IR codes and macros from macros window to save time in creating other macros. This works for delays and jumps as well."
                ]
            }
        ]
    }
]

export const Sonosdirectory = [
    {
        key: "SNS001",
        title: "1. Set Up Customer Accounts",
        content: [
            {
                type: "bullets",
                content: [
                    "Sonos offers two methods of Account Setup for end users, “customer creates account” and “create account on behalf of the customer”. For the former, simply guide customers to the below URL.",
                    "Press + hold the link below to copy the URL on a mobile device, if you wish to share it outside of this page."
                ]
            },
            {
                type: "links",
                content: [
                    {name: "Customer Creates Account", url: "https://www.sonos.com/singin/register", key: "SNS001_L1"},
                    {name: "Technician Creates Accounts", url: "https://www.sonos.com/login/ci/create", key: "SNS001_L2"},
                   ]
            },
            {
                type: "bullets",
                content: [
                    "Alternatively, if the customer is not present or available to make an account, YOU can access the below URL and create an account on Behalf of the customer. This will allow you to obtain the proper account context in order to set up devices on the system. The customer will later be notified that they have additional steps left to set up their account fully. This temporary account state will expire in 21 days, and the customer will be prompted to verify their email address through the application.", 
                ]
            },
            {
                type: "subsection",
                title: "S2 Desktop Application",
                content: [
                    "Despite the parity between the S2 mobile application and desktop application, some users may appreciate the ability to control their system from their computer.",
                    "On MacOS, this can be easily handled through Airplay with or without the application. On windows, the S2 application is recommended for communicating with devices.",

                ],
                image: "d57faf6c-4ca4-488c-e293-654ed8496900"
            },
            {
                type: "bullets",
                content: [
                    "	To easily find any platform version of the application, you can use the 			following page:", 
                ]
            },
            {
                type: "links",
                content: [
                    {name: "Sonos Downloads Page", url: "	https://support.sonos.com/en-us/downloads?language=en_US", key: "SNS001_L3"},
                   ]
            },
        ]
    },
    {
        key: "SNS002",
        title: "2. Home Theater Setup",
        content: [
            {
                type: "bullets",
                content: [
                    "Combining Sonos devices into a single room is a common workflow in the field, but there are three possible ways to approach this “Bonding” configuration. Selecting one of the three (and NOT anything in between) is recommended by the manufacturer:",
                ]
            },
            {
                type: "image",
                content: "483c488f-1c7e-4719-8b91-e1630fd66100"
            },
            {
                type: "image",
                content: "f4fdb7c4-4aba-4fad-8b8f-bb856a310500"
            },
            {
                type: "image",
                content: "985722db-dcd2-46b4-25ae-da897aa31f00"
            },
            {
                type: "image",
                content: "b6ffb039-83dc-492b-0f0a-0052c9711500"
            },
            {
                type: "bullets",
                content: [
                    "If utilizing the Home Theater Coordinator set up, it is highly recommended to connect other devices in the room to the 5GHz network access, as this will provide better communication speeds and reliable connections throughout the room.",
                ]
            },
            {
                type: "image",
                content: "13a7e3bd-4cd6-4748-2354-14e816579600"
            },
        ]
    },
    {
        key: "SNS003",
        title: "3. Installer Mode",
        content: [
            {
                type: "bullets",
                content: [
                    "Installer Mode is a feature set in the Sonos App that segments the set up workflow with knowledge that numerous devices are going to be set up. You can activate this mode BEFORE STARTING SET UP by navigating to “Gear Icon” > App Preferences > Installer Mode (under Product Setup) > Multi-Device Set Up",
                ]
            },
            {
                type: "image",
                content: "65cc7374-bec2-423d-d013-69906ef82900"
            },
        ]
    },
    {
        key: "SNS004",
        title: "4. Customer Tour",
        content: [
            {
                type: "bullets",
                content: [
                    "Gotta show off the system you just installed but you don't know the product that well? Let Sonos do the work with their handy “Product Tour” feature within the application. This can be found by navigating to “Gear Icon” > System > Device Location > Education (bottom of the list) > Learn About Your [Product Name].",
                ]
            },
        ]
    },
    {
        key: "SNS005",
        title: "5. Location Best Practices",
        content: [
            {
                type: "bullets",
                content: [
                    "Below are some best practice guidelines to consider when installing Sonos components:",
                ]
            },
            {
                type: "image",
                content: "17b10c9f-5688-49f6-f0ca-7f53b90fa700"
            },
        ]
    },
    {
        key: "SNS006",
        title: "6. Disabling Wifi ",
        content: [
            {
                type: "bullets",
                content: [
                    "Disabling wifi on devices with a wired connection is optimal ONLY WHEN the devices are located with a rack or near the Router location. This would most commonly effect Amps and Ports THAT ARE WIRED TO AN ETHERNET SOURCE.",
                ]
            },
            {
                type: "image",
                content: "caef4273-41ac-4046-f9d0-2eb75a4efc00"
            },
            {
                type: "bullets",
                content: [
                    "This setting can be found by navigating to “Gear Icon” > System > Device Location > Device (under Products) > Disable Wifi.",
                ]
            },
        ]
    },
    {
        key: "SNS007",
        title: "7. Wifi Connection Standards",
        content: [
            {
                type: "bullets",
                content: [
                    "In the case of some older Sonos models, these devices will ONLY accept connection to 5GHz networks, overlooking 2.4Ghz connections.",
                    "Most modern devices will support 802.11n (available 2.4 and 5GHz connections), but some will ask to be connected to a 2.4GHz connection specifically."
                ]
            },
            {
                type: "image",
                content: "03e5e44d-6ad2-4c65-e022-fb3ba0344200"
            },
            {
                type: "bullets",
                content: [
                    "Lastly, all modern devices will support security protocols up to WPA3 (we use WPA2 security). If you encounter an older device in the field and it fails to connect to the network, check the router's security protocols.",
                    "This is often found in the 'password' section of the 'wireless' settings. If the customer's network is set to WPA3 security, it would be an appropriate time to discuss moving down to WPA2 security.",
                    "Assuming the customer does not have a reason for using WPA3 security, this would not create any difference in experience for the customer. They may need to forget the network connection and reconnect with the new security on some devices (phone, computer, etc.)."
                ]
            },
        ]
    },
    {
        key: "SNS008",
        title: "8. Switch Configuration",
        content: [
            {
                type: "bullets",
                content: [
                    "Below are the recommended settings and their corresponding configurations for switches that will handle packet traffic for sonos devices. In instances where set up is failing, or the device has set up but is not correctly passing audio, open the managed menu interface for the switch that will be receiving traffic and confirm these configurations are matching the below table.",
                ]
            },
            {
                type: "image",
                content: "109e7449-1bfc-4417-459a-ffc734988f00"
            },
            {
                type: "bullets",
                content: [
                    "In the case of Bridge Multicast Filtering Status, DO NOT create a new VLAN for sonos devices in order to use this. REMEMBER, the default LAN that is established by the router IS a VLAN in and of itself. We would prioritize using this ID for the configuration.",
                    "In some cases, you may find it helpful to daisy chain Sonos Amps if they are away from the headend or wireless connection, and you are facing a limited number of ports on the switch. This can be done with the second Cat6 port on the back of the Amp, by placing a connection to another Amp from here.",
                ]
            },
            {
                type: "image",
                content: "0a86ed91-8344-467d-97b6-041f77728a00"
            },
            {
                type: "bullets",
                content: [
                    "However, Spanning Tree Protocol only supports up to 4 bridges from one amp. So use this option sparingly.",
                ]
            },
            {
                type: "image",
                content: "cfa3a254-5548-4ae1-5207-f218d813c200"
            },
        ]
    },
    {
        key: "SNS009",
        title: "9. Internet Port Configuration",
        content: [
            {
                type: "bullets",
                content: [
                    "Sonos products (like most others) use a combination of TCP/IP and UDP communications across various ports. Knowing some of these ports can help you identify devices in network scans and troubleshoot networking faults. A common problem you may encounter that would prevent Sonos devices from working is security measures that block traffic from these ports. If you suspect this may be an issue, check the Firewall settings on both the Router and Switch(es) and ensure none of these port numbers are blocked at this level.",
                ]
            },
            {
                type: "image",
                content: "a04b6314-6eed-43d0-5561-4f5c9dd41000"
            }
        ]
    },
    {
        key: "SNS010",
        title: "10. S1 vs S2 Compatibility",
        content: [
            {
                type: "bullets",
                content: [
                    "When setting up previous generation devices, it is pertinent to remember some devices will REQUIRE the use of the S1 application. Devices produced after April of 2015 will require the use of the S2 application. Devices produced before this date are subject to further consideration as to which app to recommend to customers.",
                    'Follow the guide below for more insight:'
                ]
            },
            {
                type: "links",
                content: [
                    {name: "Compatibility Chart", url: "https://support.sonos.com/en-us/article/sonos-s2-compatibility", key: "SNS001_L1"},
                   ]
            },
        ]
    }
]

