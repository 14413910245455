// * DEPENDS
import React, { useState, useRef, useEffect} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { validPay } from '../../utils/handlers/regex';
import {roundTo} from "../../utils/handlers/roundTo.js"

// * CSS
import '../../CSS/Pages_payments/VerifyForm.css';

// * UTILS 
import { makePayable, makeChargeable, makeCredit } from '../../utils/features/paymentSlice';

const VerifyForm_2 = () => {
    let navigate = useNavigate()
    var ref_amount = useRef(0)

    const [local, setLocal] = useState({
        amounts: {
            str_amount: "",
            int_amount: 0,
            str_charge: "",
            int_charge: 0
        },
        control: {
            ctrl_amount: false,
            ctrl_cardtype: false,
            crtl_captcha: false
        },
        credit: false
    })

    // restarts the form for new submission.
    const handleRestart = () => {
        setLocal({
            amounts: {
                str_amount: "",
                int_amount: 0,
                str_charge: "",
                int_charge: 0
            },
            control: {
                ctrl_amount: false,
                ctrl_cardtype: false,
                crtl_captcha: false
            },
            credit: false
        })
        window.location.reload()
    }

    // handles storing the amount temporarily while the user inputs
    const handleRef = (input) => {
        ref_amount = input
    }

    // handles amoutn submission to the local state manager 
    const handleAmount = (amount) => {
        if (isNaN(amount) || amount <= 0) {
            window.alert("Please enter a valid dollar amount. Do NOT include '$' in the input.")
        } else if (amount >= 1000000) {
            window.alert("we are not able to transact this amount online. Please contact our office. 608 - 835 - 0556. You are being redirected to the Home Page.")
            navigate("/")
        } else {
            setLocal((state) => ({...state, amounts: {...state.amounts, str_amount: `${amount}`}}))
            setLocal((state) => ({...state, amounts: {...state.amounts, int_amount: parseFloat(amount)}}))
            setLocal((state) => ({...state, control: {...state.control, ctrl_amount: true}}))
        }
    }

    // handles the cardtype submission 
    const handleCardType = (result) => {
        if (result === false) {
            setLocal((state) => ({...state, credit: false}))
            setLocal((state) => ({...state, control: {...state.control, ctrl_cardtype: true}}))
        } else if (result === true) {
            setLocal((state) => ({...state, credit: true}))
            setLocal((state) => ({...state, control: {...state.control, ctrl_cardtype: true}}))
        }
    }

    // handles the exitence of a captcha
    const handleCaptcha = (token) => {
        if (token) {
            setTimeout(() => {
                setLocal((state) => ({...state, control: {...state.control, crtl_captcha: true}}))
            }, 500)
        }    
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if(local.control.ctrl_amount === true && local.control.ctrl_cardtype === true && local.control.crtl_captcha === true){
            setTimeout(() => {
                // calcs the charge amount
                if(local.credit === true) {
                    local.amounts.int_charge = roundTo((local.amounts.int_amount * 0.03), 2)
                    local.amounts.str_charge = `${local.amounts.int_charge}`
                }

                // dispatch a valid payable amount, a valid charge amount (if needed, otherwise passes in "0" from initial state), and t/f on whether it is a credit card or not (yes card, no card)
                dispatch(makePayable(validPay(local.amounts.str_amount)))
                dispatch(makeChargeable(validPay(local.amounts.str_charge)))
                dispatch(makeCredit(local.credit))
                setTimeout(() => {
                    navigate("/verify/checkout")
                }, 1000)
            }, 1000)
        }
    },[dispatch, local.amounts.str_charge, local.amounts, local.control.crtl_captcha, local.control.ctrl_amount, local.control.ctrl_cardtype, local.credit, navigate])

    return(
        <div className='VerifyForm'>
            {/* amount collection  */}
            <h1 className='VerifyForm__title'>{local.control.ctrl_amount === false && local.control.ctrl_cardtype === false && local.control.crtl_captcha === false ? "Amount" : local.control.ctrl_amount === true && local.control.ctrl_cardtype === false && local.control.crtl_captcha === false ? "Card Type" : local.control.ctrl_amount === true && local.control.ctrl_cardtype === true && local.control.crtl_captcha === false ? "Captcha" : local.control.ctrl_amount === true && local.control.ctrl_cardtype === true && local.control.crtl_captcha === true ? "Thank You!" : ""}</h1>
            <div className='VerifyForm__section' id='cont_amount' style={{display: local.control.ctrl_amount === false && local.control.ctrl_cardtype === false && local.control.crtl_captcha === false ? "flex" : "none"}}>
                <label className='VerifyForm__label' htmlFor='input_payable'>Please enter your payment amount</label>
                <input 
                    className='VerifyForm__input'
                    min="0"
                    max='100000'
                    step=".01"
                    id="input_payable" 
                    name='input_payable'
                    type="number"
                    placeholder='1234.56'
                    onChange={(e) => {handleRef(e.target.value)}}
                />
                <button className='section__button' id="btn_next" onClick={() => {handleAmount(ref_amount)}}>Next</button>
            </div>

            {/* credit card confirmation */}
            <div className='VerifyForm__section' id='cont_amount' style={{display: local.control.ctrl_amount === true && local.control.ctrl_cardtype === false && local.control.crtl_captcha === false ? "flex" : "none"}}>
                <label className='VerifyForm__label' id="cpy_creditnotice">We assess a 3% surcharge on transactions completed with credit cards. <br/> This is not greater than our cost of acceptance.</label> 
                <label className='VerifyForm__label'>What type of card will you be using?</label> 
                <div className='section__buttonContainer'>
                    <button className='section__button' id='btn_cardtypeY' onClick={() => {handleCardType(true)}}>Credit</button>
                    <button className='section__button' id='btn_cardtypeN' onClick={() => {handleCardType(false)}}>Debit</button>
                </div>
            </div>

            {/* captcha */}
            <div className='VerifyForm__section' id='cont_amount' style={{display: local.control.ctrl_amount === true && local.control.ctrl_cardtype === true && local.control.crtl_captcha === false ? "flex" : "none"}}>
                <label className='VerifyForm__label'>Please complete the captcha verification.</label>
                <ReCAPTCHA
                    sitekey='6LcUklgiAAAAAENTpw-UCvCZO_YIDq--eEYIBxL3'
                    onChange={handleCaptcha}
                    className='VerifyForm__frame'
                    id="frame_capt"
                />
            </div>
            <button className='VerifyForm__restart' id="btn_formreset" onClick={handleRestart} style={{display: local.control.ctrl_amount === true && local.control.ctrl_cardtype === true && local.control.crtl_captcha === true ? "none" : "flex"}}><img id='img_restart' src='/restart.svg' alt='restart form' style={{height: "30px", width: "30px"}}/> Start Over</button>
            <p className='VerifyForm__caption' style={{display: local.control.ctrl_amount === true && local.control.ctrl_cardtype === true && local.control.crtl_captcha === true ? "block" : "none"}}>One moment, we are securing your transaction.</p>
        </div>
    )
};

export default VerifyForm_2;
