export const brands = [
  {
    url: "https://ambisonicsystems.com/",
    lable: "Ambisonic",
    finalid: "BND1",
    imageURL: "../assets/icons/",
  },
  {
    url: "https://www.accessnetworks.com/",
    lable: "Access Networks",
    finalid: "BND2",
  },
  {
    url: "https://www.arcam.co.uk/our-products.htm",
    lable: "Arcam",
    finalid: "BND3",
  },
  {
    url: "https://www.artnovion.com/",
    lable: "Artnovion Acoustics",
    finalid: "BND4",
  },
  {
    url: "https://www.symetrix.co/partners/ashly/",
    lable: "Ashly",
    finalid: "BND5",
  },
  {
    url: "https://www.asisecuritynow.com/",
    lable: "ASI Security Systems",
    finalid: "BND6",
  },
  { url: "https://www.acinfinity.com/", lable: "AC Infinity", finalid: "BND7" },
  { url: "https://www.anthemav.com/", lable: "Anthem", finalid: "BND8" },
  {
    url: "http://araknisnetworks.com/",
    lable: "Araknis Networks",
    finalid: "BND9",
  },
  { url: "http://www.atlona.com/", lable: "Atlona", finalid: "BND10" },
  { url: "https://www.atlasied.com/", lable: "Atlas IED", finalid: "BND11" },
  { url: "https://avproedge.com/", lable: "AV Proedge", finalid: "BND12" },
  {
    url: "http://www.audiocontrol.com/index.aspx",
    lable: "Audio Control",
    finalid: "BND13",
  },
  {
    url: "http://www.audio-technica.com/cms/site/c35da94027e94819/index.html",
    lable: "AudioTechnica",
    finalid: "BND14",
  },
  { url: "http://www.auralex.com/", lable: "Auralex", finalid: "BND15" },
  {
    url: "https://www.baldwinhardware.com/",
    lable: "Baldwin Locks",
    finalid: "BND16",
  },
  { url: "https://www.bluesound.com/", lable: "Bluesound", finalid: "BND17" },
  { url: "https://www.brightsign.biz/", lable: "BrightSign", finalid: "BND18" },
  {
    url: "http://cambridgesound.com/qtpro-sound-masking/?gclid=CjwKCAjw47bLBRBkEiwABh-PkTRrtYiEMUrUrB9gtPJuceY2MUMenOSxJmR3gO-d_aBto1kCwIVkBxoCeTkQAvD_BwE",
    lable: "Cambridge Sound Masking",
    finalid: "BND19",
  },
  { url: "http://www.chiefmfg.com/", lable: "Chief", finalid: "BND20" },
  { url: "https://www.clarecontrols.com/", lable: "Clare", finalid: "BND21" },
  {
    url: "http://www.contacta.co.uk/products#largearea",
    lable: "Contacta Hearing Loops",
    finalid: "BND22",
  },
  {
    url: "https://www.classeaudio.com/",
    lable: "Classe Audio",
    finalid: "BND23",
  },
  { url: "http://www.control4.com/", lable: "Control 4", finalid: "BND24" },
  { url: "https://www.crownaudio.com/en-US", lable: "Crown", finalid: "BND25" },
  { url: "http://www.da-lite.com/", lable: "Da-Lite", finalid: "BND26" },
  {
    url: "https://www.danleysoundlabs.com/",
    lable: "Danley Sound Labs",
    finalid: "BND27",
  },
  { url: "http://www.dbxpro.com/", lable: "DBX Pro", finalid: "BND28" },
  {
    url: "https://www.definitivetechnology.com/",
    lable: "Definitive Technology",
    finalid: "BND29",
  },
  {
    url: "https://usa.denon.com/us/home",
    lable: "Denon + HEOS",
    finalid: "BND30",
  },
  {
    url: "http://www.digitalprojection.com/",
    lable: "Digital Projection",
    finalid: "BND31",
  },
  {
    url: "https://www.dragonflyscreens.com/",
    lable: "Dragonfly Projection Screens",
    finalid: "big",
  },
  {
    url: "http://www.earthquakesound.com/",
    lable: "Earthquake",
    finalid: "BND33",
  },
  {
    url: "https://epson.com/projectors-and-displays",
    lable: "Epson Projectors",
    finalid: "BND34",
  },
  {
    url: "https://www.furutech.com/products/",
    lable: "Furutech",
    finalid: "BND35",
  },
  {
    url: "https://www.hegel.com/en/",
    lable: "Hegel Music Systems",
    finalid: "BND36",
  },
  {
    url: "https://integrahometheater.com/",
    lable: "Integra",
    finalid: "BND37",
  },
  { url: "https://iportproducts.com/", lable: "iPort", finalid: "BND38" },
  { url: "https://isoacoustics.com/", lable: "IsoAcoustics", finalid: "BND39" },
  {
    url: "https://www.jbl.com/home-audio/",
    lable: "JBL Home Audio",
    finalid: "BND40",
  },
  { url: "http://www.jblpro.com/www/home", lable: "JBL Pro", finalid: "BND41" },
  {
    url: "https://www.jblsynthesis.com/",
    lable: "JBL Synthesis",
    finalid: "BND42",
  },
  {
    url: "https://jgeigershading.com/",
    lable: "J. Geiger Shades",
    finalid: "BND43",
  },
  {
    url: "https://www.jlaudio.com/pages/home-audio",
    lable: "JL Home Audio",
    finalid: "BND44",
  },
  {
    url: "http://justaddpower.com/implementation.html?gclid=Cj0KCQiAw8OeBhCeARIsAGxWtUwLXvYMRTVcgGlpfblcRWaf6ZoI5joYxzVlEBePWeYavsdldCIFn5caAv0mEALw_wcB",
    lable: "Just Add Power",
    finalid: "BND45",
  },
  {
    url: "http://procision.jvc.com/index.jsp",
    lable: "JVC Projectors",
    finalid: "BND46",
  },
  {
    url: "https://www.kaleidescape.com/systems/movie-players-servers/",
    lable: "Kaleidscape",
    finalid: "BND47",
  },
  { url: "https://us.kef.com/", lable: "Kef", finalid: "BND48" },
  { url: "https://kimber.com/", lable: "Kimber Kable", finalid: "BND49" },
  { url: "http://www.klipsch.com/", lable: "Klipsch", finalid: "BND50" },
  {
    url: "https://www.klipsch.com/custom-install",
    lable: "Klipsch Custom Install",
    finalid: "BND51",
  },
  {
    url: "https://www.klipsch.com/heritage-series-speakers",
    lable: "Klipsch Heritage ",
    finalid: "BND52",
  },
  { url: "http://www.klipsch.com/pro", lable: "Klipsch Pro", finalid: "BND53" },
  { url: "https://klhaudio.com/", lable: "KLH Audio", finalid: "BND54" },
  { url: "[https://www.kramerav.com/", lable: "Kramer AV", finalid: "BND55" },
  {
    url: "http://www.kramerus.com/",
    lable: "Kramer Electronics",
    finalid: "BND56",
  },
  { url: "https://www.kwikset.com/", lable: "Kwikset Locks", finalid: "BND57" },
  { url: "http://labgruppen.com/", lable: "Lab Gruppen", finalid: "BND58" },
  { url: "https://www.leakaudio.com/", lable: "Leak Audio", finalid: "BND59" },
  {
    url: "https://leaprofessional.com/",
    lable: "Lea Professional",
    finalid: "BND60",
  },
  {
    url: "https://www.legrand.us/audiovisual",
    lable: "Legrand AV",
    finalid: "BND61",
  },
  {
    url: "https://www.lexicon.com/products/",
    lable: "Lexicon",
    finalid: "BND62",
  },
  {
    url: "https://www.listentech.com/listentalk/?utm_source=google&utm_medium=cpc&utm_campaign=1444385987&utm_content=61868842928&utm_term=listen%20technologies&source=paid_digital&gad_source=1&gclid=EAIaIQobChMI__vVu47ChAMV8llHAR3YGwFWEAAYASAAEgI5ZfD_BwE",
    lable: "Listen Technologies",
    finalid: "BND63",
  },
  { url: "http://www.lge.com/us/index.jsp", lable: "LG", finalid: "BND64" },
  {
    url: "https://www.logitech.com/en-us?&utm_source=google&utm_medium=cpc&utm_campaign=Logitech%20-%20DTX%20-%20Brand%20Term%20-%20General%20-%20Logitech%20Term%20-%20ROAS%20-%20US&gclid=Cj0KCQiAw8OeBhCeARIsAGxWtUw_MvezIbJHcJGKxX7DuDNII65ZvCpyZH8g9-cE2Iar8J0bKWBs9ksaAvhbEALw_wcB",
    lable: "Logitech",
    finalid: "BND65",
  },
  { url: "https://www.lowellmfg.com/", lable: "Lowell", finalid: "BND66" },
  {
    url: "https://www.lumasurveillance.com/",
    lable: "Luma Surveillance",
    finalid: "BND67",
  },
  { url: "http://www.lutron.com/", lable: "Lutron", finalid: "BND68" },
  {
    url: "https://www.lutron.com/en-US/Products/Pages/WholeHomeSystems/Homeworks/Overview.aspx",
    lable: "Lutron HomeWorks",
    finalid: "BND69",
  },
  {
    url: "https://www.lutron.com/en-US/Residential-Commercial-Solutions/Pages/Residential-Solutions/ShadingSolutions.aspx",
    lable: "Lutron Shades",
    finalid: "BND70",
  },
  { url: "https://madvrenvy.com/", lable: "MadVR Labs", finalid: "BND71" },
  {
    url: "http://www.us.marantz.com/us/pages/home.aspx",
    lable: "Marantz",
    finalid: "BND72",
  },
  {
    url: "http://www.marklevinson.com/",
    lable: "Mark Levinson",
    finalid: "BND73",
  },
  {
    url: "https://www.mantelmount.com/?gclid=CjwKCAjw_dTMBRBHEiwApIzn_ESKeM39q6CdUXUEUnce3b--eEU-D0gisymxaYXFILV0PeEON0UaNxoCFS4QAvD_BwE",
    lable: "MantleMount",
    finalid: "BND74",
  },
  {
    url: "https://www.legrandav.com/products/middle_atlantic_products",
    lable: "Middle Atlantic",
    finalid: "BND75",
  },
  {
    url: "https://www.mofidistribution.com/our-brands",
    lable: "MoFI",
    finalid: "BND76",
  },
  { url: "http://www.tvlift.com/", lable: "Nexus", finalid: "BND77" },
  { url: "http://www.nuvotechnologies.com/", lable: "NuVo", finalid: "BND78" },
  {
    url: "https://www.pakedge.com/",
    lable: "Pakedge Networks",
    finalid: "BND79",
  },
  { url: "http://www.panasonic.com/", lable: "Panasonic", finalid: "BND80" },
  { url: "https://www.pangeaaudio.com/", lable: "Pangea", finalid: "BND81" },
  {
    url: "https://www.peachtreeaudio.com/",
    lable: "Peachtree Audio",
    finalid: "BND82",
  },
  {
    url: "https://www.polkaudio.com/en-us/",
    lable: "Polk Audio",
    finalid: "BND83",
  },
  {
    url: "https://www.powersoft.com/en/",
    lable: "Powersoft",
    finalid: "BND84",
  },
  { url: "https://www.presonus.com/", lable: "Presonus", finalid: "BND85" },
  {
    url: "https://www.primaluna-usa.com/",
    lable: "PrimaLuna",
    finalid: "BND86",
  },
  { url: "https://ptzoptics.com/", lable: "PTZ Optics", finalid: "BND87" },
  {
    url: "http://www.omnimount.com/consumer/Default.aspx",
    lable: "Omnimount",
    finalid: "BND88",
  },
  {
    url: "https://onkyousa.com/?gad_source=1&gclid=EAIaIQobChMIjeXDw4fChAMV6zfUAR2XTwYTEAAYASAAEgKvcfD_BwE",
    lable: "Onkyo",
    finalid: "BND89",
  },
  {
    url: "https://originacoustics.com/",
    lable: "Origin Acoustics",
    finalid: "BND90",
  },
  { url: "https://www.qsc.com/", lable: "QSC", finalid: "BND91" },
  {
    url: "https://www.qsc.com/products-solutions/q-sys/",
    lable: "Q-SYS Control",
    finalid: "BND92",
  },
  {
    url: "https://www.pangeaaudio.com/brands/record-doctor",
    lable: "Record Doctor",
    finalid: "BND93",
  },
  { url: "https://www.paradigm.com/en/#", lable: "Paradigm", finalid: "BND94" },
  {
    url: "https://pioneerhomeusa.com/",
    lable: "Pioneer Home Audio",
    finalid: "BND95",
  },
  {
    url: "http://www.puritanaudiolabs.com/",
    lable: "Puritan Audio Labs",
    finalid: "BND96",
  },
  {
    url: "https://www.rdlnet.com/index.php?language=2",
    lable: "Radio Design Labs",
    finalid: "BND97",
  },
  { url: "https://rel.net/", lable: "REL", finalid: "BND98" },
  { url: "http://www.revelspeakers.com/", lable: "Revel", finalid: "BND99" },
  { url: "https://ring.com/", lable: "Ring", finalid: "BND100" },
  { url: "https://rowoneht.com/", lable: "Rowone Seating", finalid: "BND101" },
  { url: "https://www.russound.com/", lable: "Russound", finalid: "BND102" },
  {
    url: "http://www.salamanderdesigns.com/",
    lable: "Salamander Designs",
    finalid: "BND103",
  },
  {
    url: "http://www.samsung.com/us/consumer/index.html",
    lable: "Samsung",
    finalid: "BND104",
  },
  { url: "http://www.sanus.com/us/en/", lable: "Sanus", finalid: "BND105" },
  {
    url: "http://www.screeninnovations.com/",
    lable: "Screen Innovations (SI)",
    finalid: "BND106",
  },
  { url: "https://www.seura.com/", lable: "Seura", finalid: "BND107" },
  { url: "http://www.schlage.com/", lable: "Schlage", finalid: "BND108" },
  { url: "http://www.shure.com/americas", lable: "Shure", finalid: "BND109" },
  {
    url: "https://solidsteel.it/?gclid=Cj0KCQiAw8OeBhCeARIsAGxWtUzvSLXb7c1LsMDr4_P_pE3CMd203Q9SUIUKE5bOK-49yOJ1XOIpyTMaAjHkEALw_wcB",
    lable: "Solid Steel",
    finalid: "BND110",
  },
  { url: "http://www.sonos.com/en-us/home", lable: "Sonos", finalid: "BND111" },
  { url: "http://www.sony.com/index.php", lable: "Sony", finalid: "BND112" },
  {
    url: "http://www.sonypremiumhome.com/receivers/",
    lable: "Sony ES",
    finalid: "BND113",
  },
  {
    url: "https://pro.sony/ue_US/products/professional-displays/product-line-up",
    lable: "Sony Professional Displays",
    finalid: "BND114",
  },
  {
    url: "http://www.sonypremiumhome.com/projectors/",
    lable: "Sony Projectors",
    finalid: "BND115",
  },
  {
    url: "https://www.stealthacoustics.com/",
    lable: "Stealth Acoustics",
    finalid: "BND116",
  },
  {
    url: "http://www.stewartfilmscreen.com/",
    lable: "Stewart Filmscreens",
    finalid: "BND117",
  },
  {
    url: "http://www.sunbritetv.com/",
    lable: "SunBrite TV",
    finalid: "BND118",
  },
  { url: "http://espsurgex.com/surgex/", lable: "Surge X", finalid: "BND119" },
  { url: "https://www.symetrix.co/", lable: "Symmetrix", finalid: "BND120" },
  { url: "http://www.tdgaudio.com/", lable: "TDG Audio", finalid: "BND121" },
  { url: "http://www.tannoy.com/", lable: "Tannoy", finalid: "BND122" },
  {
    url: "https://www.tlnetworx.com/",
    lable: "Techlogix Networx",
    finalid: "BND123",
  },
  {
    url: "http://www.triadspeakers.com/",
    lable: "Triad Speakers",
    finalid: "BND124",
  },
  {
    url: "http://www.universalremote.com/",
    lable: "Universal Control (URC Remote)",
    finalid: "big",
  },
  {
    url: "https://www.velodyneacoustics.com/en/",
    lable: "Velodyn Acoustics",
    finalid: "BND126",
  },
  {
    url: "https://www.vincent-tac.de/en/home.html",
    lable: "Vincent Audio",
    finalid: "BND127",
  },
  {
    url: "https://www.snapav.com/shop/en/snapav/surge-conditioning",
    lable: "Wattbox Power and IP Control",
    finalid: "big",
  },
  {
    url: "http://www.wbtusa.com/pages/contact.html",
    lable: "WBT",
    finalid: "BND129",
  },
  { url: "https://www.wiimhome.com/", lable: "Wiim", finalid: "BND130" },
  { url: "http://www.xantech.com/", lable: "Xantech", finalid: "BND131" },
  {
    url: "http://www.yaleresidential.com/en/yale/yaleresidential-com/Real-Living/",
    lable: "Yale Real Living",
    finalid: "BND132",
  },
  {
    url: "https://usa.yamaha.com/products/audio_visual/index.html",
    lable: "Yamaha",
    finalid: "BND133",
  },
  { url: "https://www.zeevee.com/", lable: "ZeeVee", finalid: "BND134" },
];

// ! timestamp (do not add to code, for record keeping only)
// 08 - 17 - 2024;
// 03 - 15 - 2024 when i made this change, we added a letter with Z so i have to unhide that in the code manually. should be a CSS change
// 08 - 17 -2024 had to add "wattbox power and IP control" to the consdieration set for having an ID of "big", i know i am breaking convention here but it does work

// ! IMPORTANT:
// due to some bugs with the parsing library, there are some weird characters that will apear due to some sort of character mis-matching AFAIK.
// I have tried to fix it a couple times and can't.

// Make sure to use ctrl+F to search for the below items and make the replacement to make this easier on yourself.

// "\xa0" === " " (a single space)
// "None" === enter the name of the brand here. (seems to be due to the HTML data set?) (Sony and Presonus tend to be this way)
