const caseid = () => {
    var prefix = "SP-";
    
    var id_init = Math.round(Math.random() * 1000000).toString();
    
    const len = id_init.length
    // console.log("init:", id_init, id_init.length);
    
    
    switch(len) {
        case 6:
            var output = `${prefix}${id_init}`
        break;
        
        case 5:
           var output = `${prefix}0${id_init}`  
        break;
        
        case 4:
            var output = `${prefix}00${id_init}`  
        break;

        case 3:
            var output = `${prefix}000${id_init}`  
        break;

        case 2:
            var output = `${prefix}0000${id_init}`  
        break;
        
        case 1:
            var output = `${prefix}00000${id_init}`  
        break;
    }

    // console.log("post processing:", output)

    return(output)
}

export default caseid