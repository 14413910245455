// depends
import axios from "axios"

// ? handles retrieval of the service and key from the server
export const fetchESJserviceID = async () => {    
    try {
        const res = await axios.get("https://website-worker.audiocontractorsllc.workers.dev/VtCBSHBM1QsDjEjtCJQ7FG5Xswg446h94ZGNdJMB2xI=")
        return res.data
    } catch (err) {
        console.log("could not reach worker", err)
    }  
}

export const fetchEJSkey = async () => {
    try {
        const res = await axios.get("https://website-worker.audiocontractorsllc.workers.dev/pALE14RC3CnIQbcRPPemWaNnzjhELfk6C9F4kT3LHQ=")
        return res.data
    } catch (err) {
        console.log("could not reach worker", err)
    }  
}