import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


import '../../CSS/Pages_payments/Checkout.css'
import { makeProcessing } from '../../utils/features/processingSlice';

const Checkout = () => {
    const a = useNavigate();
    
    const [b, setB] = useState();

    const [c, setC] = useState();
    
    const [d, setD] = useState(true);
    const [e, setE] = useState(false);

    const f = useSelector(state => { return state.payments })
    
    const g = useRef();

    const [h, setH] = useState({
        l: "",
        m: "",
        n: ""
    })
    
    useEffect(() => {
        if (h.l === "") {
            axios.get("https://website-worker.audiocontractorsllc.workers.dev/84f7593c99b2d2c0ed10903df863582ca23ff")
                .then((res) => {
                    setH({...h, l: res.data})
                })
                .catch((err) => {
                    console.log("merch ID error:", err)
                })
        }

        if (h.m === "") {
            axios.get("https://website-worker.audiocontractorsllc.workers.dev/5937de1d773e1087c626c4e58d093cc8b700fa0105b")
                .then((res) => {
                    setH({...h, m: res.data})
                })
                .catch((err) => {
                    console.log('term id error:', err)
                })
        }

        const i = document.getElementById("ClearentPayButtonContainer")

        if (!i && h.l !== "" && h.m !== "") { 
            var j = document.createElement("script")
            j.type = "application/javascript"
            j.src = `https://hpp.clearent.net/hpp/${h.l}/${h.m}`
            j.id = "clearent-hpp"
            
            j.onload = () => {
                setB(true)
            }
            
            document.getElementsByTagName("head")[0].appendChild(j)
        }

        setTimeout(() => {
            const k = document.getElementById("captcha_container")
            if (k) {
                k.hidden = true
            }
        }, 1000)

        if (h.n === ""){        
            axios.get("https://website-worker.audiocontractorsllc.workers.dev/35c84f8a49dd6f4b7f6654b5b41c028f0414")
                .then((res) => {
                    setH({...h, n: res.data})
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },[h])

    // once script is loaded and injected, start configuring the clearent paybutton
    useEffect(() => {
        // config
        if (b) {    
            if(f.credit.payload === true && window.Clearent && f.value.payload !== '') {
                // window.Clearent.setProperty("pk", `${h.n}`)
                window.Clearent.setProperty("heading-text", "Enter payment details below");
                window.Clearent.setProperty("pay-button-text",`Pay $${parseFloat(f.value.payload) + parseFloat(f.charge.payload)} now`)
                window.Clearent.setProperty("show-billing-address", "true");
                window.Clearent.setProperty("use-terminal-service-fee-setting", true);
                window.Clearent.setProperty("success-message","Payment Accepted. Please close this pop up.")
                window.Clearent.payButton({"amount": `${f.value.payload}`});
                setC(true)
            }

            if (f.credit.payload === false && window.Clearent && f.value.payload !== '') {
                // window.Clearent.setProperty("pk", `${h.n}`)
                window.Clearent.setProperty("heading-text", "Enter payment details below");
                window.Clearent.setProperty("pay-button-text",`Pay $${f.value.payload} now`)
                window.Clearent.setProperty("use-terminal-service-fee-setting", false);
                window.Clearent.setProperty("show-billing-address", "true");
                window.Clearent.setProperty("success-message","Payment Accepted. Please close this pop up.")
                window.Clearent.payButton({"amount": `${f.value.payload}`});
                setC(true)    
            }
        }

        if (window.Clearent && (f.value.payload === '' || !f.value.payload)) {
            setTimeout(() => {
                window.alert("Amount entered is not valid, you are being redirected.")
                setTimeout(() => {
                    a("/verify")
                    window.location.reload()
                },[1000])
            }, [1500])
        }

    }, [b, f, a])

    useEffect(() => {
        setTimeout(() => {
            const o = document.getElementById("Clearent-pay-now")
            if (c && o) {
                const p = g.current
                p.appendChild(o)
                setD(false)
            }
        }, 2000)

        // error handling 
        setTimeout(() => {
            const o = document.getElementById("Clearent-pay-now")
            if (!o) {
                setE(true)
            }
        }, 7000);

        // handles timeout of payments loading if there is an issue, instructs user to reload page. (120s per ceritification call)
        setTimeout(() => {
            const o = document.getElementById("Clearent-pay-now")
            const q = document.getElementById("WindowFrame")

            if (!o && q) {
                window.alert("there was an issue loading our payment workflow. Please check your internet connection and reload this page.")
            }
        }, 120000)
    }, [c])

    // code below recieves the response object,
    // notifies the comp of the transaction, 
    // updates the store with transaction information 
    // navigates user to the processing screens 
    const [r, setR] = useState(false);
    const dispatch = useDispatch();

    window.ClearentOnSuccess = (responseJSON) => {
        var s = [];
        const res = JSON.parse(responseJSON)

        s.push(res["exchange-id"])
        s.push(res.links[0].id)
        s.push(res.payload.transaction.id)
        s.push(res.payload.transaction.amount)
        s.push(res.payload.transaction.result)
        s.push(res.payload.transaction.billing.street)
        s.push(res.payload.transaction.created)

        dispatch(makeProcessing(s))

        setR(true)
    }

    window.ClearentOnPopupClosed = () => {
        if (r) {
            a("/verify/checkout/processing")
        } else {
            window.alert("Please complete checkout before continuing.")
        }
    }

    return(
        <div className='Checkout' id="Checkout" ref={g}>
            <div className='loader' style={{display: `${d ? "block" : "none"}`}}>
                <div className='loader__aniFrame'>
                    <div className='loading_homeIcon'></div>
                    <div className="loading_1"></div>
                    <div className='loading_bankIcon'></div>
                </div>
                <p className='loader_tip' style={{display: `${e ? "block" : "none"}`}}>
                still loading? try refreshing this page.</p>
            </div>

        </div>
    )
};

export default Checkout;
