// * DEPENDS 
import React, {useState, useEffect} from 'react';

// * CSS 
import '../../../CSS/Pages_payments/ProcessingStates/Active.css'

const Active = () => {

    const phrases = [
        "DO NOT LEAVE OR REFRESH THIS PAGE",
        "TUNING SPEAKERS...",
        "ADJUSTING EQ...",
        "UPDATING FIRMWARES...",
        "TURNING UP THE VOLUME..."
    ]

    const [phrase, setPhrase] = useState(phrases[0])

    const subtitle = document.getElementById("active_subtitle")

    // updates the phrase with time 
    const handlePhrase = () => {        
        setTimeout(() => {
            setPhrase(phrases[1])
        }, 2000)
        setTimeout(() => {
            setPhrase(phrases[2])
        }, 6500)
        setTimeout(() => {
            setPhrase(phrases[3])
        }, 9000)
        setTimeout(() => {
            setPhrase(phrases[0])
        }, 12000)
    }
 

    useEffect(() => {
        handlePhrase()
    },[subtitle])



    return (
        <div className='Active'>
            <div className='Active__copy'>
                <p className='copy__title'>Finalizing Sale</p>
                <p id="active_subtitle" className='copy__subtitle'>{phrase}</p>
            </div>
            <div class="Active__loader">
                <div class="loader-inner">
                    <div class="loader-line-wrap">
                        <div class="loader-line"></div>
                    </div>
                    <div class="loader-line-wrap">
                        <div class="loader-line"></div>
                    </div>
                    <div class="loader-line-wrap">
                        <div class="loader-line"></div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Active;