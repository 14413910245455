export const jobs = [
  {
    id: 1,
    title: "System Integrator",
    subtitle:
      "Audio Contractors is looking for a detail-driven Audio Visual technician to join our installation crew. $1,000 sign on bonus available!",
    postedDate: "May 2022",
    department: "Technicians",
    description: [
      "Review and understand electronic systems requirements and documentation, and utilize documentation to install systems to meet applicable specifications.",
      "Gather inventory, parts, and tools required for scheduled job.",
      "Create a secure work area and properly use safety equipment.",
      "Install low voltage cabling in both new construction and retrofit environments.",
      "Install cable support structure or drill wire paths.",
      "Splice and terminate a wide variety cables and connectors.",
      "Install support structure and/or rigging when required for components.",
      "Complete pre-assemblies and fabrication of sub systems.",
      "Install electronic components in main and remote locations.",
      "Install control programs or perform system programming tasks, when applicable.",
      "Identify, diagnose, and document any problems, errors or discrepancies of complex electronic systems.",
      "Review user documentation manuals and instructions with clients.",
      "Review final blueprints and wiring diagrams for accuracy, consult as needed with sales.",
      "Actively participate in weekly staff meetings.",
      "Responsible for obtaining, maintaining, and replacing own basic tools needed.",
      "Candidate ideally has respectful and polite nature, along with solid verbal communication skills among fellow employees and customers.",
      "Basic understanding of AV installation, construction techniques, and structured cabling.",
      "Comprehension of wiring schematics and architectural drawings.",
      "Familiarity with a variety of communication protocols (IR, Serial, TCP/IP, Control Voltage)",
      "Candidate should be able to build and work with different hard-wiring connections (balanced/unbalanced connections, CAT6 (RJ-45B), serial connections, etc.).",
      "Ability to work closely with general contractors and various construction trades.",
      "Knowledge of Microsoft office software.",
      "Demonstrate general stewardship to maintain and / or reduce operational costs.",
      "Must maintain strict financial, clientele, and personnel confidentiality information at all times.",
      "Ability to work accurately and efficiently under stress and under pressure of deadlines.",
      "Commitment to work well with others in a team environment.",
      "Communicate effectively and professionally with other trades, clients, co-workers and management.",
      "While performing the duties of this job, the employee is occasionally required to stand; walk; sit; use hands to finger, handle, or feel objects, tools or controls; reach with hands and arms; climb stairs and ladders; balance; stoop, kneel, crouch or crawl; talk or hear. The employee must occasionally lift and/or move up to 50 pounds; additional assistance is required for heavier objects. Specific vision abilities required by the job include close vision, distance vision, color vision, peripheral vision, depth perception, and the ability to adjust focus.",
    ],
    qualifications: [
    ],
    requirements: [
      "1 year of prior, full-time professional or educational experience in A/V or a related field.",
      "Current, valid drivers license.",
      "Availability for 30-50 per week.",
      "Flexible for job assignments and scheduling",
      "Able to lift 5 to 150lbs. Reasonable accommodations may be made to enable individuals with disabilities to perform the essential functions.",
      "Frequent local area travel by vehicle is required to meet with vendors and customers.  Some overnight stays may be required at job sites on occasion."
    ],
    compensation: {
      basePay: "$22.00/hr depending on experience",
      benefits: [
        "Health Insurance",
        "Retirement Planning",
        "Financial Planning (401k)",
        "Paid Holidays",
        "Paid Vacations / PTO banking",
        "Cell Phone Reimbursement",
        "Vendor Discounts",
        "Paid Certifications & Training",
      ],
    },
  },
  {
    id: 2,
    title: "Sales + Project Manager",
    subtitle:
      "Audio Contractors is looking for a friendly & focused Audio Visual design expert to join our residential and commerical team!",
    postedDate: "May 2022",
    department: "Sales",
    description: [
      "Generation of sales and building client base, while providing excellent customer service and striving for customer satisfaction.",
      "Ability to communicate with general contractors and members of various construction trades.",
      "Ability to analyze, plan, schedule and implement project installation.",
      "Strong verbal and written communication skills.",
      "Must maintain strict financial, clientele, and personnel confidentiality at all times.",
      "Professional and prompt customer service and customer follow-up.",
      "Ability to interact effectively and in a supportive manner with staff, clients, and vendors.",
      "Demonstrate general stewardship to maintain and/ or reduce operational costs at all times.",
      "Comprehension of wiring schematics and architectural drawings, as well as building codes.",
      "Ability to work with minimal supervision.",
      "Commitment to work well with others in a team environment.",
      "Ability to work well under stress at times, and to work accurately and efficiently under pressure of deadlines.",
      "Prepared for short, on site visits with clients, on-site techs, or contractors. The site environment could be a construction job site in progress with exposure to the elements. It may or may not be climate controlled in the early stages of work.  Exposure to loud noise is common.  The proper safety equipment must be worn as required. ",
      "While performing the duties of this job, the employee is occasionally required to stand; walk; sit; use hands to finger, handle, or feel objects, tools or controls; reach with hands and arms; climb stairs and ladders; balance; stoop, kneel, crouch or crawl; talk or hear. The employee must occasionally lift and/or move up to 50 pounds; additional assistance is required for heavier objects. Specific vision abilities required by the job include close vision, distance vision, color vision, peripheral vision, depth perception, and the ability to adjust focus."
    ],
    qualifications: [
    ],
    requirements: [
      "Three or more years in Audio Video Automation industry preferred.",
      "Must be experienced with Microsoft Office programs.",
      "Valid drivers license",
      "Availability 35-50 hour per week",
      "Knowledge and experience with installation of AV equipment.",
      "Knowledge and understanding of construction techniques and structured cabling.",
    ],
    compensation: {
      basePay: "$20.00/hr base pay, commission pay based on performance.",
      benefits: [
        "Health Insurance",
        "Retirement Planning",
        "Financial Planning (401k)",
        "Paid Holidays",
        "Paid Vacations / PTO banking",
        "Cell Phone Reimbursement",
        "Vendor Discounts",
        "Paid Certifications & Training",
      ],
    },
  },
];
