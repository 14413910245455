// DEPENDS 
import React from "react";


// CSS
import '../../CSS/TOB/TOBBanner.css';

const TOBBanner = () => {

    return(
        <div className="TOBBanner">
            <div className="TOBBanner__container" id='container_TOB'>
                <div className="container__logo" id="logo_TOB"></div>
            </div>
            <div className="TOBBanner__container" id='container_ACTOB'>
                <div className="container__logo"id='logo_ACTOB'></div>
            </div>
        </div>
    );
};


export default TOBBanner;