// * DEPENDS.
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// * CSS
import '../CSS/Footer.css'

const Footer = () => {
    // handles hiding the comp when there is a gallery displayed
    const hide = useSelector(state => { return state.gallery.value})

    return(
        <div className="Footer" style={{display: hide ? "none" : "flex"}}>
            <div className='Footer__contactContainer'>
                    <div className='contactContainer__inTouchCont'>
                        <h3 className='inTouch__title'>Get in Touch</h3>
                        <p className='inTouch__leade'>Our typical office hours are 8:00a-4:00p, Monday through Friday with weekends available by appointment.</p>
                    </div>
                    <div className='contactContainer__contactLinkContainer'>
                    <a href="tel:6088350556" className='contactLinkContainer__contactLink_link'>
                        <div className='contactLinkContainer__contactLink' id='contactLink_email'>
                        <img src='./phone.svg' className='contactLink__img' id='CLimg_phone'/>
                        <p className='contactLink__title'>608 - 835 - 0556</p>
                        </div>
                    </a>
                    <a href="mailto:info@audiocontractorsllc.com" className='contactLinkContainer__contactLink_link'>
                        <div className='contactLinkContainer__contactLink' id='contactLink_phone'>
                            <img src='./mail.svg' className='contactLink__img' id='CLimg_email'/> 
                            <p className='contactLink__title'>info@audiocontractorsllc.com</p>  
                        </div>
                    </a>
                    <a href="https://goo.gl/maps/9BRRkMkA3fwEeDy37" className='contactLinkContainer__contactLink_link' target="_blank" rel="noopener noreferrer">
                        <div className='contactLinkContainer__contactLink' id='contactLink_addsress'>
                            <img src='./map.svg' className='contactLink__img' id='CLimg_map'/>
                            <p className='contactLink__title'>1015 N Main Street</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="Footer__siteMap">
                <div class="siteMap__container" id='StMpCntn_1'>
                    <p id="SM_homepage" className="siteMap__link"><Link to="/">Home</Link></p>
                    <p id="SM_brands" className="siteMap__link"><Link to='/brands'>Brands</Link></p>
                    <p id="SM_experts" className="siteMap__link"><Link to="/team">Our Team</Link></p>
                    <p id="SM_careers" className="siteMap__link"><Link to='/apply'>Careers</Link></p>
                    <p id="SM_verify" className="siteMap__link"><Link to='/verify'>Pay Online</Link></p>
                    <p id="SM_support" className="siteMap__link"><Link to='/support'>Customer Support</Link></p>
                    <p id="SM_sales" className="siteMap__link"><a href="mailto:info@audiocontractorsllc.com" className='siteMap__link'>Contact Sales Team</a></p>
                    <p id="SM_TOBhomes" className="siteMap__link"><Link to='/tobhomes'>Tim Obrien Homes</Link></p>
                </div>

                <div class="siteMap__container" id='StMpCntn_2'>
                    <p id="SM_services" className="siteMap__link"><Link to='/serviceslist'>Services</Link></p>
                        <p id="SM_services_resi" className="siteMap__sublink"><Link to='/residentialservices'>Residential Services</Link></p>
                        <p id="SM_services_comm" className="siteMap__sublink"><Link to='/commercialservices'>Commercial Services</Link></p>
                </div>

                <div class="siteMap__container" id='StMpCntn_3'>
                    <p id="SM_gallery" className="siteMap__link"><Link to='/gallery'>Gallery</Link></p>
                        <p id="SM_gallery_resi" className="siteMap__sublink"><Link to='/gallery/residentialgallerylist'>Residential Gallery</Link></p>
                        <p id="SM_gallery_comm" className="siteMap__sublink"><Link to='/gallery/commercialgallerylist'>Commercial Gallery</Link></p>
                </div>
            </div> 
        </div>
    );
};

export default Footer;
