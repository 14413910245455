// depends
import React from 'react';
import {Link} from 'react-router-dom';

// css
import "../../CSS/Pages_team/HiringNotif.css"


const HiringNotif = () => {

    return(
        <div className='HiringNotif'>
            <Link to="/apply" className='HiringNotif__link'>We are hiring <span id='HNlink_icon'>&#8594;</span></Link>
        </div>
    )
};

export default HiringNotif;

