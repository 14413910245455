// depends 
import React from "react";
import { Navigate } from "react-router";


const ProtectedRoute = ({ status, children }) => {
  console.log("status:", status)
    if (status === null) {
      return <Navigate to="/verify/trust" replace />;
    } else if (status !== "b451d2ef9468816a038d2") {
      return <Navigate to="/verify/trust" replace />;
    } else if (status === "b451d2ef9468816a038d2")
    return children;
};

export default ProtectedRoute;