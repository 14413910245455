// depends 
import React from 'react';

//css 
import "../../CSS/Knowledge/TopicFrame.css";

const TopicFrame = (props) => {
    return (
        <div className='TopicFrame'>
            <h1 className='TopicFrame__title' id='ttl_topicframe'>{props.material.title}</h1>
            <div className='TopicFrame__materialframe'>
                {props.material.content.map((item) => {
                    if (item.type === "callout") {
                        return (
                            <div className='materialframe__calloutcontainer'>
                                <p className='calloutcontainer__callout'>{item.content}</p>
                            </div>
                        )
                    }
                    if (item.type === "bullets") {
                        var counter = 0
                        return (
                            <div className='materialframe__bulletcontainer' id={`bulletcontainer_${props.material.key}_${counter}`}>
                                {item.content.map((bullet) => {
                                    counter += 1
                                    return(
                                        <p className='bulletcontainer__bullet' id={`blt_${counter}`}>{bullet}</p>
                                    )
                                })}
                            </div>
                        )
                    }
                    if (item.type === "image") {
                       return <img className='materialframe__img' src={`https://imagedelivery.net/rpS5WjBdELVvj2iH8_wiuw/${item.content}/public`} alt={`display of ${item.content}`} />
                    }
                    if (item.type === "links") {
                        return(
                            <div className='materialframe__linkgroup'>
                                <ul className='linkgroup__list'>
                                    {item.content.map((item) => {
                                        return <a className='glink__link' href={item.url} target="_blank" rel="noopener noreferrer"><li className='list__glink' key={item.key} id={item.key}>{item.name}</li></a>
                                    })}
                                </ul>
                            </div>
                        )
                    }
                    if (item.type === "subsection") {
                        return (
                            <div className='materialframe__subsection'>
                                <h3 className='subsection__title'>{item.title}</h3>
                                {item.content.map((item) => {
                                    return <p className='subsection__materialcontent'>{item}</p>
                                })}
                                {item.image ? <img src={`https://imagedelivery.net/rpS5WjBdELVvj2iH8_wiuw/${item.image}/public`} alt={`display of ${item.content}`} style={{height: "100%", width: "80%"}}/> : null}
                            </div>
                        )
                    }
                    else {
                        console.log("could not determine material type?", item)
                        return null
                    }
                })}
            </div>
        </div>
    );
};

export default TopicFrame;