// DEPENDS 
import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import '../../CSS/NewHelp/NewHelp.css'

const NewHelp = () => {
    // used to show the menu selection that will probably be added to over time. 
    const forms = [
        {
            id: 0,
            title: 'Network Personalization >',
            desc: 'If your installation package includes home networking, fill out this form to help us pre-configure your network to uniquely support you.',
            URL: '/GettingStarted/LAN'
        },
        
    ]

    return(
        <div className='NewHelp'>
                <div className='NewHelp__welcome'>
                    <h1 className='welcome__title'>We're excited to be a part of your dream home!</h1>
                    <h3 className='welcome__subtitle'>From our whole team, thank you for trusting our 20+ years in expertice & excellence.</h3>
                    <p className='welcome__copy'>We know that your move-in or installation date may be well into the future, but the completion of forms below will assure a simple and painless process.</p>
                    <p className='welcome__copy'>Your Sales/Project Manager should have already spoken with you about the relevant forms for your project. Please select the links below to get started.</p>
                    {/* <p className='welcome__copy'>If at any time you need to return to this page, please use the Return button at the bottom of the form.</p> */}
                    <p className='welcome__copy' id='warn_welcomenh'>If you leave the form page without submitting the form, your input will be lost!</p>
                </div>
                {forms.map((item) => {
                    return (
                        <Link className='menu__link' to={`${item.URL}`} key={`link_NH${item.id}`}>
                            <h3 className='link__title' id={`link_NHtitle`}>{item.title}</h3>
                            <p className='link__desc' id={`link_NHdesc`}>{item.desc}</p>
                        </Link>
                    )
                })}
        </div>
    );
};

export default NewHelp;