// DEPENDS. 
import React from 'react';
import {Link} from 'react-router-dom';

// COMPS
import TOBBanner from './TOBBanner';
import TOBMarketing from './TOBMarketing';

// data
import { tobmenudata } from '../../assets/data/tobmenudata';

// CSS 
import '../../CSS/TOB/TOBMenu.css';

const TOBMenu = () => {
    var count = 0;

    return(
        <div className='TOBMenu' id="container_TOBintro">
            <TOBBanner />
            <div className='TOBMenu__copyContainer'>
                <h1 className='copyContainer__title' id='title_tobintro'>Welcome!</h1>
                <h3 className='copyContainer__subtitle' id="subtitle_tobintro">Let's get your home up and running, today!</h3>
                <p className='copyContainer__copy' id="copy_tobintro">It's important to us that you are able to settle into your home as smooth as possible.<br/><br/> When you arrive on move in day, you may notice a few technological accommodations. The instructions below are intended to help you understand and make the most out of the low-voltage installation in your home.<br/><br/> Click on one of the links below to get started!</p>
            </div>
            <div className='TOBMenu__guideContainer'>
                {   
                    tobmenudata.map((item) => {
                        if (count % 2 === 0) {
                            count += 1
                            return (
                                <Link className='guideContainer__link' to={`/TOBHomes${item.url}`}>
                                    <div className='guideContainer__guideItem' key={item.id} id={item.id} style={{flexDirection: "row"}}>
                                        <div className='guideItem__copyContainer'>
                                            <h1 className='guideItem__title' id={`guidetitle_${item.id}`}>{item.name}</h1>
                                            <p className='guideItem__subtitle' id={`guidesubtitle_${item.desc}`}>{item.desc}</p>
                                        </div>
                                        <img className='guideItem__photo' id={`guidePhoto_${item.id}`} src={item.photoURL} alt={`${item.name} menu`}/>
                                    </div>
                                </Link>
                            )
                        } else {
                            count += 1
                            return (
                                <Link className='guideContainer__link' to={`/TOBHomes${item.url}`}>
                                    <div className='guideContainer__guideItem' key={item.id} id={item.id} style={{flexDirection: "row-reverse"}}>
                                        <div className='guideItem__copyContainer'>
                                            <h1 className='guideItem__title' id={`guidetitle_${item.id}`}>{item.name}</h1>
                                            <p className='guideItem__subtitle' id={`guidesubtitle_${item.desc}`}>{item.desc}</p>
                                        </div>
                                        <img className='guideItem__photo' id={`guidePhoto_${item.id}`} src={item.photoURL} alt={`${item.name} menu`}/>
                                    </div>
                                </Link>
                            )
                        }
                    })
                }
            </div>
            <TOBMarketing />
        </div>
    );
};

export default TOBMenu;