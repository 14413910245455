import emailjs from '@emailjs/browser';

const sendReceipt = (service, data, key) => {
    const templates = {
        default: "template_71knjd4",
        RESI_INTERNET: "template_cn5c9ii",
        RESI_SONOS: "template_xf4qhdr",
        RESI_STV: "template_vyj8eqy",
        RESI_C4: "template_jnluts1"
    }

    switch(data.category) {
        case "Internet Connection Not Working / Poor Performance":
            emailjs.send(service, templates.RESI_INTERNET, data, key)
            .then((res) => {
                // console.log('receipt:', res)
                return res.status
            })
            .catch((err) => {
                // console.log("no receipt sent",err)
                return err.status
            })
        break
        case "Home Automation Not Working":
            emailjs.send(service, templates.RESI_C4, data, key)
            .then((res) => {
                // console.log('receipt:', res)
                return res.status
            })
            .catch((err) => {
                // console.log("no receipt sent",err)
                return err.status
            })
        break
        case "Audio Not Working":
            emailjs.send(service, templates.RESI_SONOS, data, key)
            .then((res) => {
                // console.log('receipt:', res)
                return res.status
            })
            .catch((err) => {
                // console.log("no receipt sent",err)
                return err.status
            })
        break
        case "Video Not Working":
            emailjs.send(service, templates.RESI_STV, data, key)
            .then((res) => {
                // console.log('receipt:', res)
                return res.status
            })
            .catch((err) => {
                // console.log("no receipt sent",err)
                return err.status
            })
        break
        default:
            emailjs.send(service, templates.default, data, key)
            .then((res) => {
                // console.log('receipt:', res)
                return res.status
            })
            .catch((err) => {
                // console.log("no receipt sent",err)
                return err.status
            })
    }
}

export default sendReceipt
