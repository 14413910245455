import React from "react";

import "../../CSS/Pages_home/Contact.css"

const Contact = () => {


    return(
        <div className="Contact">
            <div className="Footer__companyContainer">
                <div className="infoContainer__socialContainer">
                    <a className='social__link' href="https://www.facebook.com/audiocontractors/" target="_blank" rel="noopener noreferrer">
                        <div id="facebook" className="socialContainer__social"></div>
                    </a>
                    <a className='social__link' href="https://www.linkedin.com/company/audio-contractors-llc/" target="_blank" rel="noopener noreferrer">
                        <div id="instagram" className="socialContainer__social"></div>
                    </a>
                    <a className='social__link' href="https://www.bbb.org/us/wi/oregon/profile/electronic-equipment-dealers/audio-contractors-llc-0694-14038344" target="_blank" rel="noopener noreferrer">
                        <div id="BBB" className="socialContainer__social"></div>        
                    </a>
                </div>
                <div id="madisonbuilders" className="companyContainer__affiliateIcon"></div>
            </div>
        </div>
    )
}

export default Contact;