// * DEPENDS *
import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';

// * UTILS *
import { replace } from '../utils/handlers/replace.js';
import ProtectedRoute from '../utils/comps/ProtectedRoute.js';

// * ON-PAGE COMPS *
import Nav from './Nav.js'
import Footer from './Footer.js';
import Copyright from './Copyright';

// * ROUTE PAGES 
import HomePage from '../Pages/HomePage.js';
import Team from '../Pages/Team.js'
import Brands from './Pages_services/Brands.js';
import NotFound from './Pages_aux/NotFound.js';
import Apply from './Pages_careers/Apply.js';
import Gallery from '../Pages/Gallery.js';
import GalleryFrame from './Pages_gallery/GalleryFrame.js';
import ServicesList from '../Pages/SerivcesList.js';
import SalesApplication from './Pages_careers/SalesApplication.js';
import TechApplication from './Pages_careers/TechApplication.js';
import Positions from './Pages_careers/Positions.js';
import ResidentialServices from './Pages_services/ResidentialServices.js';
import CommercialServices from './Pages_services/CommercialServices.js';
import ResidentialGalleryList from './Pages_gallery/ResidentialGalleryList.js';
import CommercialGalleryList from './Pages_gallery/CommercialGalleryList.js';
import TestimonialGallery from './Pages_Testimonials/TestimonialGallery.js';
import Confirmation from './Pages_aux/Confirmation.js';
import WindowFrame from "./Pages_payments/WindowFrame.js"
import TOBMenu from './TOB/TOBMenu.js';
import WifiIntro from './TOB/Docs/WifiIntro.js';
import Wifioption1 from './TOB/Docs/Wifioption1.js';
import Wifioption2 from './TOB/Docs/Wifioption2.js';
import Caseta from './TOB/Docs/Caseta.js';
import Ring from './TOB/Docs/Ring.js';
import NewHelp from './NewHelp/NewHelp.js';
import LANform from './NewHelp/LANform.js';
import KnowledgeHome from './Knowledge/KnowledgeHome.js';
import TopicHome from './Knowledge/TopicHome.js';
import BBSForm from './Knowledge/BBSForm.js';
import Trust from './Pages_aux/Trust.js';
import TrustNav from './Pages_aux/TrustNav.js';
import NewTicket from './Pages_customerSupport/NewTicket.js';
import SupportMenu from './Pages_customerSupport/CustomerSupportMenu.js';

// * CSS
import "../CSS/App.css"


function App() {
  const location = useLocation()  

  useEffect(() => {
    if(replace(location)) {
      document.getElementsByClassName("Footer")[0].style.display = "flex"
      document.getElementsByClassName("Copyright")[0].style.display = "flex"
    }
    
  }, [location])

  return (
    <div id="App" className="App">
        <Nav />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<HomePage />} />
          <Route exact path="/brands" element={<Brands />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/apply" element={<Apply />} />
          <Route exact path="/apply/positions" element={<Positions />} />
          <Route exact path="/apply/positions/1" element={<TechApplication />} />
          <Route exact path="/apply/positions/2" element={<SalesApplication />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route path="gallery/residentialgallerylist/:id" element={<GalleryFrame/>} />
          <Route path="gallery/commercialgallerylist/:id" element={<GalleryFrame />} />
          <Route exact path="/gallery/residentialgallerylist" element={<ResidentialGalleryList />} />
          <Route exact path="/gallery/commercialgallerylist" element={<CommercialGalleryList />} />
          <Route exact path="/serviceslist" element={<ServicesList />} />
          <Route exact path="/residentialServices" element={<ResidentialServices />} />
          <Route exact path="/commercialServices" element={<CommercialServices />} />
          <Route exact path="/testimonials" element={<TestimonialGallery />} />
          <Route exact path="/confirmation" element={<Confirmation />} />
          <Route exact path="/verify/*" element={<WindowFrame />} />
          <Route exact path="/TOBHomes" element={<TOBMenu />} />
          <Route exact path="/TOBHomes/homenetworking" element={<WifiIntro />} />
          <Route exact path="/TOBHomes/homenetworking/option1" element={<Wifioption1 />} />
          <Route exact path="/TOBHomes/homenetworking/option2" element={<Wifioption2 />} />
          <Route exact path='/TOBHomes/caseta' element={<Caseta />} />
          <Route exact path='/TOBHomes/ringdoorbell' element={<Ring />} />
          <Route exact path='/support' element={<SupportMenu />} />
          <Route exact path='/support/newTicket' element={<NewTicket />} />
          <Route exact path='/verify/trust/nav' element={
            <ProtectedRoute status={localStorage.getItem("2d1d3ae82e36374bab60")}>
              <TrustNav />
            </ProtectedRoute>
          } />
          <Route exact path='/GettingStarted' element={<NewHelp/>} />
          <Route exact path='/GettingStarted/LAN' element={<LANform />} />  
          <Route exact path='/kh' element={
            <ProtectedRoute status={localStorage.getItem("2d1d3ae82e36374bab60")}>
              <KnowledgeHome/>
            </ProtectedRoute>
          } />
          <Route exact path='/kh/urcremotes' element={
            <ProtectedRoute status={localStorage.getItem("2d1d3ae82e36374bab60")}>
              <TopicHome/>
            </ProtectedRoute>
          } />
          <Route exact path='/kh/*' element={
          <ProtectedRoute status={localStorage.getItem("2d1d3ae82e36374bab60")}>
            <TopicHome/>
          </ProtectedRoute>
          } />
          <Route exact path='/kh/suggestion' element={
            <ProtectedRoute status={localStorage.getItem("2d1d3ae82e36374bab60")}>
              <BBSForm/>
            </ProtectedRoute>
          } />        
          <Route exact path='verify/trust' element={<Trust/>} />
        </Routes>
        <Footer />
        <Copyright />
    </div>
  );
}

export default App;
