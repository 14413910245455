export const replace = (location) => {
    if (!location.pathname.includes("/kh",0)) {
        const footer = document.getElementsByClassName("Footer")[0]
        const copyright = document.getElementsByClassName("Copyright")[0]
        if (footer.style.display === "none" && copyright.style.display === "none") { 
          return true
        } else {
            return false
        };    
    };
};