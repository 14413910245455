// depends 
import React, {useRef} from 'react';
import { Link } from 'react-router-dom';

// data 
import { knowledgehome } from '../../assets/data/guides';

// css 
import '../../CSS/Knowledge/KnowledgeHome.css'

const KnowledgeHome = () => {
    var counter = useRef(0)

    return(
        <div className='KnowledgeHome'>
            <div className='KnowledgeHome__introContainer'>
                <h1 className='introContainer__title' id='KH_title'>AudioCon Knowledge Hub</h1>
                <p className='introContainer__caption' id='KH_caption'>Welcome to the AC knowledge hub! <br/><br/> Here you can find quick reference guides and samples of work to use in the field. Click on a link below to get started.</p>
                <Link className='introContainer__email' id='KH_emailink' to='/kh/suggestion'>Suggestions for more content?</Link>
            </div>
            <div className='KnowlegeHome__KHcontainer'>
                {knowledgehome.map((item) => {
                    counter.current += 1
                    return(
                        <Link to={`${item.url}`} className='KHContainer__listItem' key={`KHlist_${item.url}`} id={`KHlist_${counter.current}`}>
                            <p className='listItem__title' id={`KHlist_title_${counter.current}`}>{item.name}</p>
                            <p className='listitem__arrow' id={`KHlist_arrow_${counter.current}`}>&#8674;</p>
                        </Link>
                    )
                })}
            </div>
        </div>
    )
}

export default KnowledgeHome;