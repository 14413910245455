import { createSlice } from "@reduxjs/toolkit";

export const processingSlice = createSlice({
    name: 'processing',
    initialState: {
        value: []
    },  
    reducers: {
        makeProcessing: (state, arr) => {
            state.value = arr
        }
    }
});

export const {makeProcessing} = processingSlice.actions
export default processingSlice.reducer