// validation for payment handling
const regex_checkout = new RegExp(
    /^\d+\.\d{2}$/
);

export const validPay = (amount) => {
    var str_check = amount.toString()
    if (regex_checkout.test(str_check)) {
        return amount.toString()
    } else if (!str_check.includes(".")) {
        var str_corrected_1 = str_check + ".00"
        if (regex_checkout.test(str_corrected_1)) {
            return str_corrected_1
        } else {
            // error out if not able to correct
            return ''
        }
    } else if (str_check.includes(".")) {
        var str_corrected_2 = str_check + "0"
        if (regex_checkout.test(str_check)) {
            return str_corrected_2
        } else {
            // not able to correct string, erroring out. 
            return ''
        }
    }
};