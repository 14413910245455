// DEPENDS 
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import axios from 'axios';

// CSS
import '../../CSS/NewHelp/LANform.css'

const LANform = () => {
    // configure a new template in email js 
    // set up that info in the DB (dev locally though)
    
    // * deconst useForm + states for wokflow control
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ guestNet, setGuestNet ] = useState(false);

    // * used the check if the errors object is empty in order to show or hide the container at the bottom of the screen. 
    const isEmpty = (obj) => {
        if (Object.keys(obj).length === 0) {
            return false
        } else {
            return true
        }
    };

    //  * used to check the existence of a specified error, to apply element.style properties to inputs.
    const hasError = (obj, target) => {
        if (Object.keys(obj).includes(target)) {
            return true
        } else {
            return false
        }
    };

    // * handling email submissions 
    let navigate = useNavigate();

    // * getting info from service worker
    var arr_EJS = {
        service: "",
        template: "template_t8584rj",
        key: ""
    }
    
    // * service ID
    axios.get("https://website-worker.audiocontractorsllc.workers.dev/VtCBSHBM1QsDjEjtCJQ7FG5Xswg446h94ZGNdJMB2xI=")
        .then((res) => {
            return arr_EJS.service = res
        })
        .catch((err) => {
            console.log("could not reach worker", err)
        })

    // * template ID
    axios.get("https://website-worker.audiocontractorsllc.workers.dev/36dc44df33b470640b51be6040a3704765f88ea03cf2")
        .then((res) => {
            return arr_EJS.template = res
        })
        .catch((err) => {
            console.log("could not reach worker", err)
        })
            

    
    // * key    
    axios.get("https://website-worker.audiocontractorsllc.workers.dev/pALE14RC3CnIQbcRPPemWaNnzjhELfk6C9F4kT3LHQ=")
        .then((res) => {
            return arr_EJS.key = res
        })
        .catch((err) => {
            console.log("could not reach worker", err)
        })   
    
    const formSubmit = (data) => {  
        emailjs.send(arr_EJS.service.data,arr_EJS.template.data,data,arr_EJS.key.data)
            .then((res) => {
                navigate("/confirmation")
            })
            .catch((err) => {
                console.log("failure", err)
                window.alert("there was an error submitting your application. check internet connection.")
            })
    };

    return(
        <div className='LANform'>
            {/* * contact information * */}
            <form id="form_lanform" className="SalesApplication__form" onSubmit={handleSubmit(formSubmit)}>
                <div className='lanform__errors' style={{display: isEmpty(errors) ? "block" : "none"}}>
                    <p className='errors__dialog'>Please resolve the following errors before submitting:</p>
                    <ul className='errors__list'>
                        {errors.firstName && <li className='lanform__error'>&#8226; Please enter your first name</li>} 
                        {errors.lastName && <li className='lanform__error'>&#8226; Please enter your family name</li>} 
                        {errors.phone && <li className='lanform__error'>&#8226; Please enter valid phone number</li>} 
                        {errors.NetworkName && <li className='lanform__error'>&#8226; Network name cannot exceed 50 characters.</li>} 
                        {errors.passcode && <li className='lanform__error'>&#8226; Password must be 8-32 characters in length, and include at least one (1) number AND capital letter</li>} 
                        
                        
                    </ul>
                </div>
                <div className="lanform__subheaderContainer" id="container_netheader">
                    <h1 className="subheaderContainer__header">Contact Information</h1>
                </div>
                <div className="lanform__netContainer">
                    <div className="netContainer__inputContainer">
                        <label id="lable_firstName" className='lanform__lable' data-lpignore="true">First Name</label>
                        <input 
                            id="firstName"
                            name="firstName" 
                            className='lanform__input' 
                            type="text" 
                            style={{border: hasError(errors,"firstName") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                            {...register("firstName", { required: true, maxLength: 50, pattern: /^[A-Za-z]+$/i })} 
                        />
                    </div>
                    <div className="netContainer__inputContainer">
                        <label id="lable_lastName" className='lanform__lable'>Last Name</label>
                        <input 
                            id="lastName"
                            name="lastName" 
                            className='lanform__input' 
                            type="text" 
                            style={{border: hasError(errors,"lastName") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                            {...register("lastName", { required: true, maxLength: 50, pattern: /^[A-Za-z]+$/i })} 
                        />
                    </div>
                    <div className="netContainer__inputContainer">
                        <label id="lable_phone" className='lanform__lable'>Contact Phone</label>
                        <input 
                            id="phone"
                            name="phone" 
                            className='lanform__input' 
                            type="tel" 
                            style={{border: hasError(errors,"phone") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                            {...register("phone", {required: true, maxLength: 12, pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i })} 
                        />
                    </div>
                    <div className="netContainer__inputContainer">
                        <label id="lable_email" className='lanform__lable'>Contact Email</label>
                        <input id="email" name="email" className='lanform__input' type="email" {...register("email", {required: true, pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i })} />
                        {/* browser handles error message */}
                    </div>
                </div>

                {/* * Network Information * */}
                <div className="lanform__subheaderContainer" id="container_netheader">
                    <h1 className="subheaderContainer__header">Network Information</h1>
                    <p className='subheaderContainer__caption'>Below, please enter your desired network name and passcode that will be used to set up the network.</p>
                    <p className='subheaderContainer__caption'>Upon installation, the Name you select will be the name that appears on your devices when connecting to the in-home wifi.</p>
                    <p className='subheaderContainer__caption'>The password that you select will be used to secure your home's network. We recommend that you choose a mix of phrases, numbers, and symbols that are unique to your home and not easily guessed by outsiders.</p>
                </div>
                <div className="lanform__netContainer">
                    <div className="netContainer__inputContainer">
                        <label id="lable_firstName" className='lanform__lable' data-lpignore="true">Network Name</label>
                        <input 
                            id="NetworkName"
                            name="NetworkName" 
                            className='lanform__input' 
                            type="text" 
                            style={{border: hasError(errors,"NetworkName") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                            {...register("NetworkName", { required: true, maxLength: 50})} 
                        />
                    </div>
                    <div className="netContainer__inputContainer">
                        <label id="lable_passcode" className='lanform__lable'>Desired Password<br/><span id='label_specialWifi'>(Passcode should be 8-32 characters with capitalizations & numbers)</span></label>
                        <input 
                            id="passcode"
                            name="passcode" 
                            className='lanform__input' 
                            type="text" 
                            style={{border: hasError(errors,"passcode") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                            {...register("passcode", { required: true, maxLength: 32, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,32}$/i })} 
                        />
                    </div>
                    <div className='lanform__guestContainer'>
                        <div className='guestContainer__inputContainer'>
                            <label id='lable_guestCheck' className='lanform__lable'>Would you like to have <br/> a guest network <br/> in your home?</label>
                            <input id="guestCheck" className='lanform__box' type="checkbox" onClick={() => {guestNet ? setGuestNet(false) : setGuestNet(true)}} {...register("guestCheck")} />
                        </div>
                        {guestNet ?  
                            <div className='lanform__listContainer'>
                                <p className='listContainer__instructions'>Please include the desired name and password information for your Guest Network configuration below.</p>
                                <div className="netContainer__inputContainer">
                                    <label id="lable_firstName" className='lanform__lable' data-lpignore="true">Guest Network Name</label>
                                    <input 
                                        id="GuestNetworkName"
                                        name="GuestNetworkName" 
                                        className='lanform__input' 
                                        type="text" 
                                        style={{border: hasError(errors,"GuestNetworkName") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                                        {...register("GuestNetworkName", { required: true, maxLength: 50 })} 
                                    />
                                </div>
                                <div className="netContainer__inputContainer">
                                    <label id="lable_lastName" className='lanform__lable'>Desired Password<br/><span id='label_specialWifi'>(Passcode should be 8-32 characters with capitalizations & numbers)</span></label>
                                    <input 
                                        id="guestpasscode"
                                        name="guestpasscode" 
                                        className='lanform__input' 
                                        type="text" 
                                        style={{border: hasError(errors,"guestpasscode") ? "1.5px solid #F20000" : "1.5px solid #1E132A"}}
                                        {...register("guestpasscode", { required: true, maxLength: 50, pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,32}$/i })} 
                                    />
                                </div>
                            </div> 
                        : null}
                    </div>
                    <input id='submit_lanform' type="submit" lable="submit" value="Submit"/>
                </div>
            </form>
        </div>
    );
};

export default LANform;
