// * DEPENDS 
import React from 'react';

// * CSS 
import "../../CSS/Pages_payments/Help.css"

const Help = () => {

    return (
        <div className='Help'>
            <p className='Help__title'>Trouble accessing payments?</p>
            <p className='Help__copy'>We're happy to help. <br/> Reach out below.</p>
            <div className='Help__container'>
                <p class="phone__number">
                    <span class="phone__icon"> </span> 
                    <a href="tel:6087339616">608 - 733 - 9616</a>
                </p>
                <div class="Help__email">
                    <span class="email__icon"> </span>
                    <a href="mailto:egonzalez@audiocontractorsllc.com">egonzalez@audiocontractorsllc.com</a>
                </div>
            </div>
        </div>
    )
};

export default Help;