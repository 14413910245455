// * DEPENDS 
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router';

// * CSS 
import "../../../CSS/Pages_payments/ProcessingStates/Success.css"

const Success = () => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate("/")
        }, 3500)
    }, [navigate])

    return (
        <div className="Success"> 
                <div className='Success__copy'>
                    <p className='copy__title'>Success!</p>
                    <p id="successTip" className='copy__subtitle'>YOU ARE BEING REDIRECTED...</p>
                </div>
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> 
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
        </div>
    );
};

export default Success; 