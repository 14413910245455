// * DEPENDS 
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';


// * CSS 
import "../../../CSS/Pages_payments/ProcessingStates/Error.css"


const Error = () => {
    const [showButton, setShowButton] = useState(false)
    const navigate = useNavigate();

    setTimeout(() => {
        setShowButton(true)
    }, 6000)

    return(
        <div className='Error'>
            <div className='Error__copy'>
                <p id="title_error" className='copy__title'>There was an issue</p>
                {
                    !showButton 
                    ?
                    <p id="userTip" className='copy__subtitle'>
                        the bank handled your payment successfully, but there was an error on our end. <br/><br/>
                        we will reach out if we can't recieve your payment.
                    </p>
                    :
                    <p className='copy__subtitle'>Thank you for paying online!</p>

                }
            </div>
            {
                !showButton ?              
                <svg class="errmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
                        <circle class="errmark__circle" cx="26" cy="26" r="25" fill="none"/> 
                        <path class="errmark__x" fill="none" d="M16 16 36 36 M36 16 16 36"/>
                </svg>
                :
                <div className='Error__homeBtnContainer'>
                    <button 
                        id="btn__processingError" 
                        className='homeBtnContainer__button'
                        onClick={() => {navigate("/")}}
                    >Return to Home Page</button>
                </div>
            }          



        </div>
    );
};

export default Error;