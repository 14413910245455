
// ! KH will push the user to the topic frame no matter what, 
// ! then the topic frame will pull a string from the URL to determine which guide to render 
// ! the .map() function will then iterate over whichever guide will be needed, pulling the titles 
// ! to create a list of links that will then jump to the TopicFrame, 
// ! the topic frame will look at which link was clicked and iterate over that index of the guide 
// ! to display the content within 

// depends
import React, {useState, useEffect, useRef} from "react";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';

// data 
import { URCdirectory, Sonosdirectory } from "../../assets/data/guides";

// comps
import TopicFrame from "./TopicFrame";

// css 
import '../../CSS/Knowledge/TopicHome.css'

const TopicHome = () => {
    const location = useLocation();
    const [controller, setController] = useState({
        loaded: false,
        focus: "",
        reading: false,
        guide: ''
    })
    var topicmenu = useRef([])

    const createMenu = (arr) => {
        var cnstrctr_menu = []
        arr.map((item) => {
            cnstrctr_menu.push({topic: item.title, key: item.key})
            return cnstrctr_menu
        })
        topicmenu.current = cnstrctr_menu
    }

    const handleReadingState = (item, event) => {
        // if we are not reading right now .. then do .. 
        if (controller.focus === "" && !controller.reading) {
            if(controller.focus !== item.key) {setController({...controller, focus: item.key, reading: true})}
        }
         
        const closebtn = document.getElementById(`topicclose_${item.key}`)
         
        // if we are reading right now then reset, if event is coming from closebtn
        if (controller.reading && event.target === closebtn) {
            setController({...controller, focus: "", reading: false})
        }

        // if the user clicks another topic while reading one already
        if (controller.reading && event.target !== closebtn) {
            if(controller.focus !== item.key) {setController({...controller, focus: item.key})}
        }
    }

    

    useEffect(() => {
        if (topicmenu.current.length === 0) {   
            if (location.pathname === "/kh/urcremotes") {
                createMenu(URCdirectory)
                setTimeout(() =>{
                    setController({...controller, guide: 'URCdirectory', loaded: true})
                }, 800)
            }
            if (location.pathname === '/kh/sonos') {
                createMenu(Sonosdirectory)
                setTimeout(() =>{
                    setController({...controller, guide: 'Sonosdirectory', loaded: true})
                }, 800)
            }
            // add others as needed here
        }
        const footer = document.getElementsByClassName("Footer")[0]
        const copyright = document.getElementsByClassName("Copyright")[0]
        footer.style.display = "none"
        copyright.style.display = "none"
    },[controller, location])


    
    return(
        <div className="TopicHome" style={{height: controller.loaded ? "100%":"100vh"}}>
            <div className="TopicHome__loadingcontainer" style={{display: controller.loaded ? "none" : "flex"}}>
                <div class="three-quarter-spinner"></div>
            </div>
            <div className="TopicHome__menucontainer" style={{display: controller.loaded ? "flex" : "none"}}>
                <div className='menucontainer__introContainer'>
                    <h1 className='introContainer__title' id='TH_title'>AudioCon Knowledge Hub</h1>
                    <p className='introContainer__caption' id='TH_caption'>Select one of the topics below to get started</p>
                    <Link className='introContainer__email' id='KH_emailink' to='/kh/suggestion'>Suggestions for more content?</Link>
                </div>
                {topicmenu.current.map((item) => {
                return(
                    <div key={item.key} className='menucontainer__topic' id={`topichome_${item.key}`} onClick={(e) => {handleReadingState(item,e)}} style={{height: controller.focus === item.key ? "90vh" : "200px", padding: controller.focus === item.key ? "0 0 0 0":"3.4% 3% 4% 3%"}}>
                            <p className='topic__title' id={`topiclist_title_${item.key}`} style={{display: controller.focus === item.key ? "none" : "block"}}>{item.topic}</p>
                            <p className='topic__arrow' id={`topiclist_arrow_${item.key}`} style={{display: controller.focus === item.key ? "none" : "block"}}>&#8674;</p>
                             {controller.guide === 'URCdirectory' ? 
                                <div className="topic__frame" style={{display: controller.reading && controller.focus === item.key ? "flex":"none"}}>
                                    <TopicFrame material={URCdirectory.find((e) => e.key === item.key)}/>
                                    <button className="frame__closebutton" onClick={(e) => {handleReadingState(item, e)}} id={`topicclose_${item.key}`}>Close Section</button>
                                </div> : 
                                controller.guide === 'Sonosdirectory' ? 
                                <div className="topic__frame" style={{display: controller.reading && controller.focus === item.key ? "flex":"none"}}>
                                    <TopicFrame material={Sonosdirectory.find((e) => e.key === item.key)}/>
                                    <button className="frame__closebutton" onClick={(e) => {handleReadingState(item, e)}} id={`topicclose_${item.key}`}>Close Section</button>
                                </div> : null
                            }
                    </div>
                )
                })}
            </div>



        </div>
    )
}

export default TopicHome;
